import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouterStore } from '@ao/utilities';
import { ViewerCoreFacade } from '../../../store/viewer-core-store.facade';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'viewer-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss'],
  standalone: false,
})
export class AuthErrorComponent implements OnInit, OnDestroy {
  @HostBinding('class.viewer-error') className = true;

  error$ = this.viewerCoreFacade.errorState$;
  queryParams$: Observable<Record<string, unknown>>;
  private destroy$ = new Subject<void>();

  _supportEmail = '';

  constructor(
    private viewerCoreFacade: ViewerCoreFacade,
    private routerStore: RouterStore,
    private meta: Meta,
  ) {
    this.queryParams$ = this.routerStore.pipe(map((route) => route.state.queryParams));

    this.meta.updateTag({
      name: 'viewport',
      content: ['width=device-width', 'initial-scale=1.0'].join(', '),
    });
  }

  ngOnInit() {
    // fetch query params
    this.queryParams$.pipe(takeUntil(this.destroy$)).subscribe((queryParams: any) => {
      const { code, support } = queryParams;
      this._supportEmail = support;
      this.viewerCoreFacade.triggerError({
        status: 'error',
        code,
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
