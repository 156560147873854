import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AcademyMessageTraining, ImageMedia } from '@ao/data-models';
import { ImageHandlerMedia } from '@ao/shared-data-models';
import { fixProtocol, getThumbnailUrl } from '@ao/utilities';

@Component({
  selector: 'ao-academy-section-training',
  templateUrl: './academy-section-training.component.html',
  styleUrls: ['./academy-section-training.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AcademySectionTrainingComponent {
  @HostBinding('class.ao-academy-section-training') className = true;

  @Input() training: AcademyMessageTraining;
  @Input() sectionDisabled = false;
  @Input() academyId = 0;
  @Input() isJustOneTraining = false;

  get trainingStatus() {
    if (this.sectionDisabled || this.training.disabled) {
      return 'gray';
    } else if (this.training.passed) {
      return 'green';
    } else {
      if (this.training.completed && !this.training.passed) {
        if (
          this.training.retakeMaxAttempts &&
          (this.training.retakeMaxAttempts === -1 || this.training.retakeMaxAttempts > this.training.attempts)
        ) {
          return 'yellow';
        } else {
          return 'red';
        }
      } else {
        return null;
      }
    }
  }

  get backgroundImage() {
    if (this.training?.previewImage || this.training?.media) {
      const imageUrl = getThumbnailUrl(this.training?.previewImage || (this.training.media as ImageMedia), 240);
      return `url(${fixProtocol(imageUrl)})`;
    }
  }

  get trackImageId() {
    return (
      (this.training?.media as ImageMedia)?.unsplash_image_id ||
      (this.training?.media as ImageHandlerMedia)?.unsplashImageId ||
      ''
    );
  }
}
