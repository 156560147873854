import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * This component should only be used in old Angular since the `aoUnsplashTrackViews` directive can't be downgraded.
 * Should be removed when we have migrated to new Angular.
 */
@Component({
  selector: 'ao-unsplash-track-views',
  templateUrl: './unsplash-track-views.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UnsplashTrackViewsComponent {
  @Input() imageId: string;
}
