import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BrowserName, BrowserOSName } from '@ao/utilities';

@Component({
  selector: 'ao-add-to-homescreen',
  templateUrl: './add-to-homescreen.component.html',
  styleUrls: ['./add-to-homescreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AddToHomescreenComponent {
  @HostBinding('class.ao-add-to-homescreen') className = true;

  @Input() os: BrowserOSName = BrowserOSName.iOS;
  @Input() browser: BrowserName = undefined;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();

  get name() {
    return this.title.getTitle();
  }

  get osName() {
    return this.os ? this.os.toLowerCase() : '';
  }

  get samsung() {
    return BrowserName.SamsungInternet;
  }

  get imageUrl() {
    const tag = document.querySelector(
      this.os === BrowserOSName.iOS
        ? `link[rel="apple-touch-icon"][sizes="180x180"]`
        : `link[rel="icon"][sizes="192x192"]`,
    );
    return (tag && tag.getAttribute('href')) || '';
  }

  get params() {
    return {
      icon: /* html*/ `<img class="ao-add-to-homescreen__icon" src="/assets/action-icon-${this.osName}.png"/>`,
      safari: /* html*/ `<img class="ao-add-to-homescreen__icon--safari" src="/assets/tiny-safari-icon.png"/>`,
      samsung_menu: /* html*/ `<img class="ao-add-to-homescreen__icon--samsung" src="/assets/action-icon-samsung-menu.png"/>`,
      samsung_add: /* html*/ `<img class="ao-add-to-homescreen__icon--samsung-add" src="/assets/action-icon-samsung-add.png"/>`,
    };
  }

  get compatibleBrowser() {
    const androidSupported = [BrowserName.Chrome, BrowserName.SamsungInternet];
    return this.os === BrowserOSName.iOS
      ? this.browser === BrowserName.Safari
      : androidSupported.indexOf(this.browser) > -1;
  }

  constructor(
    private meta: Meta,
    private title: Title,
  ) {}
}
