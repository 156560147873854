import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ThemeHandlerService } from '@ao/common-ui';
import { IconSize, Theme } from '@ao/data-models';
import { color as c } from '@ao/utilities';

@Component({
  selector: 'ao-badge-icon',
  templateUrl: './badge-icon.component.html',
  styleUrls: ['./badge-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BadgeIconComponent {
  @HostBinding('class.ao-badge-icon') className = true;
  @HostBinding('class.ao-badge-icon--unread')
  @Input()
  hasUnread: boolean;
  @Input()
  iconName: string;
  @Input() imgUrl: string;
  @Input() size: IconSize = 30;

  theme: Theme;

  constructor(themeHandler: ThemeHandlerService) {
    themeHandler.registerStyle('BadgeIconComponent', (theme) => {
      this.theme = theme;
      return `
        .ao-badge-icon__group-icon {
          color: ${c(theme.nav_icon_color, '#ffffff')};
        }
      `;
    });
  }
}
