import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ao-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PaginationComponent {
  @Input() currentPage: number;
  @Input() numPages: number;

  @Output() previousPage = new EventEmitter();
  @Output() nextPage = new EventEmitter();

  onPreviousPage() {
    if (this.currentPage > 0) {
      this.previousPage.emit();
    }
  }

  onNextPage() {
    if (this.currentPage + 1 < this.numPages) {
      this.nextPage.emit();
    }
  }
}
