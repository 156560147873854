import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { escapeHTML } from '../../utils';

@Pipe({
  name: 'nl2br',
  standalone: false,
})
export class Nl2BrPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value = '', escapeHtml = true): any {
    if (typeof value === 'string') {
      const result = (escapeHtml ? escapeHTML(value) : value).replace(/(?:\r\n|\r|\n)/g, '<br/>');
      return this.sanitizer.bypassSecurityTrustHtml(result);
    }
    return value;
  }
}
