import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Language } from '@ao/data-models';
import { filterWithCharacterFolding } from '@ao/utilities';

@Component({
  selector: 'ao-auto-translation-onboarding-modal',
  templateUrl: './auto-translation-onboarding-modal.component.html',
  styleUrls: ['./auto-translation-onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AutoTranslationOnboardingModalComponent {
  @HostBinding('class.ao-auto-translation-onboarding-modal') className = true;
  @Input() open = false;
  @Input() modalInsideModal = false;
  @Input() set availableLanguages(value: Language[]) {
    this.languages = value;
    this.filteredLanguages = value;
  }
  @Output() skip = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<Language>();

  languages: Language[];
  filteredLanguages: Language[];
  searchVisible = false;
  searchInput = '';
  selectedLanguage: Language;
  showError = false;

  onSkip() {
    this.toggleSearch(true);
    this.skip.emit();
  }

  onConfirm() {
    if (!this.selectedLanguage) {
      this.showError = true;
    } else {
      this.confirm.emit(this.selectedLanguage);
    }
  }

  onSearch(search: string) {
    this.filteredLanguages = search
      ? filterWithCharacterFolding(this.languages, search, (item) => item.languageName)
      : this.languages;
  }

  toggleSearch(forceClose = false) {
    this.searchVisible = forceClose ? false : !this.searchVisible;
  }

  selectLanguage(language: Language) {
    this.selectedLanguage = language;
    this.showError = false;
    this.toggleSearch();
  }

  resetLanguage() {
    this.selectedLanguage = {
      languageCode: null,
      languageName: 'None',
    };
    this.toggleSearch();
  }
}
