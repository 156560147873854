import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Directive({
  selector: '[aoMessageModalCardFooter]',
  standalone: false,
})
export class MessageModalCardComponentFooterDirective {}

@Component({
  selector: 'ao-message-modal-card',
  templateUrl: './message-modal-card.component.html',
  styleUrls: ['./message-modal-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('container', [
      state('void', style({ opacity: 0, transform: 'scale(0.7)' })),
      transition(':enter', animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 }))),
    ]),
  ],
  standalone: false,
})
export class MessageModalCardComponent {
  @HostBinding('class.ao-message-modal-card') className = true;
  // if were using modals inside modals, the content will overlap... so we want to add another opaque layer
  @Input() modalInsideModal?: boolean = false;
  @Input() loginPage = false;
  @Input() headerText?: string = '';
  @Input() subHeaderText?: string = '';
  @Input() open = false;
  @Input() autoWidth = false;
  @Input() maxWidth = '600px';
  @Input() showClose = true;
  @Input() overflowVisible = false; // sometimes we need the overflow to be visible, e.g with dropdowns
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();

  @ContentChild(MessageModalCardComponentFooterDirective, { read: TemplateRef }) aoModalFooter: TemplateRef<any>;

  onClose() {
    if (this.showClose) {
      this.close.next(null);
    }
  }

  preventBubbling(event) {
    event.stopPropagation();
  }
}
