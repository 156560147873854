import { Pipe, PipeTransform } from '@angular/core';
import { round } from '../../utils/round';

@Pipe({
  name: 'round',
  standalone: false,
})
export class RoundPipe implements PipeTransform {
  transform(value: number, decimals = 0): any {
    return round(value, decimals);
  }
}
