import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

type DrawerPositions = 'top' | 'right' | 'bottom' | 'left';

@Component({
  selector: 'ao-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DrawerComponent {
  @Input() position: DrawerPositions = 'right';
  @Input() open = false;
  @Input() maxWidth?: string = undefined;
  @Output() closeHandler = new EventEmitter<void>();

  onClose() {
    this.closeHandler.emit();
  }

  get positionClass() {
    return 'ao-drawer-position--' + this.position;
  }
}
