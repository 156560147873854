import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { friendlyNumber } from '../../utils/friendly-number';

// converts numbers in thousands to display as fx: 1.2K. locale aware (k / m)
@Pipe({
  name: 'friendlyNumber',
  pure: false,
  standalone: false,
})
export class FriendlyNumberPipe implements PipeTransform, OnDestroy {
  private langSubscription: Subscription;

  constructor(
    @Optional() private translate: TranslateService,
    private cdf: ChangeDetectorRef,
  ) {
    if (translate) {
      // no cleanup, alive for the whole session
      this.langSubscription = translate.onLangChange.subscribe(() => {
        this.cdf.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  transform(value: number): string {
    const lang = (this.translate && this.translate.currentLang) || 'en';
    return friendlyNumber(value, lang);
  }
}
