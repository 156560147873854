import { Pipe, PipeTransform } from '@angular/core';
import { Linkifier } from './linkifier';

@Pipe({
  name: 'linkify',
  standalone: false,
})
export class LinkifyPipe implements PipeTransform {
  private linkifer: Linkifier;

  constructor() {
    this.linkifer = new Linkifier();
  }

  transform(value: string): string {
    const linkified = this.linkifer.link(value);
    return linkified;
  }
}
