import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlTree } from '@angular/router';
import { SocialContact } from '@ao/data-models';

@Component({
  selector: 'ao-peer-view',
  templateUrl: './peer-view.component.html',
  styleUrls: ['./peer-view.component.scss'],
  standalone: false,
})
export class PeerViewComponent {
  @HostBinding('class.ao-peer-view') className = true;

  @Input() originKeycode: [string, string];
  @Input() contact: SocialContact;
  @Input() mode: 'DESKTOP' | 'MOBILE' = 'DESKTOP';
  @Input() hasRemoveButton = false;
  @Input() showChat = false;
  @Output() dismiss = new EventEmitter();
  @Output() remove = new EventEmitter();

  @HostListener('click', ['$event']) onClick(event) {
    if (event.target === this.element.nativeElement) {
      this.onDismiss();
    }
  }

  constructor(
    private element: ElementRef,
    private router: Router,
  ) {}

  onDismiss() {
    this.dismiss.emit();
  }

  onCloseDialog() {
    this.dismiss.emit();
  }

  onRemoveFromGroup() {
    this.remove.emit();
  }

  onActionClick(type: string, actionTarget: string | number) {
    // due to an issue in installed PWA the redirect needs to be handled on a callback
    // More info: https://bugs.chromium.org/p/chromium/issues/detail?id=792990#c2
    if (!actionTarget) {
      return;
    }

    let tree: UrlTree;
    let segments: UrlSegment[];

    switch (type) {
      case 'email':
        window.location.href = 'mailto:' + actionTarget;
        break;
      case 'tel':
        window.location.href = 'tel:' + actionTarget;
        break;
      case 'sms':
        window.location.href = 'sms:' + actionTarget;
        break;
      case 'chat':
        tree = this.router.parseUrl(this.router.routerState.snapshot.url);
        segments = tree.root.children[PRIMARY_OUTLET].segments;

        this.router.navigate([segments[0].path, segments[1].path, 'chat'], {
          queryParams: { chatTarget: actionTarget },
        });
        break;
    }
  }
}
