import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ACTIMO_COLORS, SkeletonItemType } from '@ao/data-models';

export type SkeletonItemTypeString = keyof typeof SkeletonItemType;

@Component({
  selector: 'ao-skeleton-item',
  templateUrl: './skeleton-item.component.html',
  styleUrls: ['./skeleton-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SkeletonItemComponent {
  @Input() type: SkeletonItemType | SkeletonItemTypeString;

  @HostBinding('class.ao-skeleton-item') readonly className = true;

  readonly skeletonItemType = SkeletonItemType;
  readonly ACTIMO_COLORS = ACTIMO_COLORS;
}
