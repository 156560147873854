import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ACTIMO_COLORS } from '@ao/data-models';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

// keep local reference to custom chart elements
const scatterChartElements = {
  targetName: undefined,
};

// colors for consistant use
const color = {
  lightGray: ACTIMO_COLORS.snow.dark,
  gray: ACTIMO_COLORS.snow.darker,
  black: ACTIMO_COLORS.ink.base,
  green: ACTIMO_COLORS.green.dark,
  white: '#ffffff',
};

// create translations array to pass into translation service
let translatedTexts: string[] = [i18n('Target')];

@Component({
  selector: 'ao-spider-chart',
  templateUrl: './spider-chart.component.html',
  styleUrls: ['./spider-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SpiderChartComponent implements OnInit, OnChanges {
  @Input() target?: number | null = null;
  @Input() maxValue?: number = 9;
  @Input() data: number[];
  @Input() labels?: string[];
  // axis translations are applied within the controller so pass them in
  @Input() translatedAxisNames?: string[];

  _translations: string[] = [];
  _config: any = null;
  options$ = new ReplaySubject<any>(1);
  private chart: any;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    translatedTexts = [...(this.translatedAxisNames || []), ...translatedTexts];
    // translations that need to be rendered from within the controller

    this.translate
      .get(translatedTexts)
      .pipe(take(1))
      .subscribe((translatedValues) => {
        this._translations = translatedValues;
        if (!this._config && this.data) {
          // wait for the component to render, otherwise highcharts will take wrong dimensions
          setTimeout(() => this.buildHighcharts());
        }
      });
  }

  ngOnChanges(c: SimpleChanges) {
    // compare arrays
    const newChanges = JSON.stringify(c['data'].currentValue) !== JSON.stringify(c['data'].previousValue);

    if (this.chart && newChanges) {
      this.chart.series[0].setData(this.cleanData());
    }
  }

  onLoad(chart) {
    this.chart = chart;
    chart.reflow();
  }

  private buildHighcharts() {
    // translate default categories
    const translatedCategories = this.labels.map((category) => {
      const matchingCategory = translatedTexts[category] || category;
      return matchingCategory >= 0 ? this._translations[matchingCategory] : category;
    });

    // generic chart overrides
    const common = {
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          color: color.black,
          lineWidth: 1,
        },
      },
      legend: {
        enabled: false,
        borderColor: 'transparent',
      },
    };

    // product prefers specific positioning of the axis, so generate a custom start point
    const categoryCount = this.labels.length;
    // 4 axis is a special case, odd ones should have the spare axis on the bottom, the rest are equal
    const startAngle = categoryCount === 4 ? 45 : categoryCount % 2 === 0 ? 0 : 180;

    // work around "this" inside functions
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _self = this;
    this._config = {
      ...common,
      chart: {
        polar: true,
        type: 'line',
        height: '70%',
        margin: 16,
        events: {
          render: function () {
            // spacing from top (26.4% reseved to labels)
            const padding = this.plotBox.y + (this.plotBox.height * 0.264) / 2;
            // the target (white outer circle) height "0.736" since the circle is 73% of plotBox,  "2" since its a circle
            const targetHeight =
              (((this.plotBox.height * 0.736) / _self.maxValue) * (_self.maxValue - _self.target)) / 2;

            // cleanup previous render
            for (const elem of Object.values(scatterChartElements)) {
              if (elem) {
                elem.destroy();
              }
            }
            if (_self.target) {
              // plotBox.x - width of where the left label is
              // this.plotBox.width - width of the chart grid
              const chartWidth = this.plotBox.x + this.plotBox.width;

              // "target" text (text, x, y) "2" to put it in the middle, "4" for vertical align in relation to text height
              scatterChartElements.targetName = this.renderer
                .text(
                  _self._translations['Target'].toUpperCase(),
                  (chartWidth + this.plotBox.x) / 2,
                  padding + targetHeight / 2 + 4,
                )
                .attr({
                  fill: ACTIMO_COLORS.ink.lightest,
                  fontSize: 12,
                  zIndex: 2,
                  align: 'center',
                })
                .add();
            }
          },
        },
      },
      pane: {
        startAngle: startAngle,
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
          },
          dataLabels: {
            inside: true,
            enabled: true,
            y: 11,
            style: {
              textOutline: false,
              fontWeight: 'normal',
              color: color.black,
            },
            formatter: function () {
              return this.point.y;
            },
          },
          marker: {
            fillColor: color.white,
            lineWidth: 2,
            color: color.white, // inherit from series
            radius: 10,
            lineColor: color.green,
            zIndex: 3,
            states: {
              hover: {},
              normal: {},
            },
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        gridLineInterpolation: 'circle',
        lineWidth: 0,
        min: 0,
        max: _self.maxValue,
        labels: {
          reserveSpace: true,
          enabled: false,
        },
        plotLines: [
          {
            value: _self.maxValue,
            width: 8,
            dashStyle: 'Solid',
            color: color.lightGray,
          },
        ],
        plotBands: _self.target
          ? [
              {
                from: 0,
                to: _self.target,
                color: color.lightGray,
              },
            ]
          : [],
      },
      xAxis: {
        categories: translatedCategories,
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          overflow: 'justify',
        },
      },
      series: [
        {
          color: color.green,
          name: '',
          data: this.cleanData(),
          pointPlacement: 'on',
        },
      ],
    };
    // build is called from within subscribe which needs to poke change detection:
    this.options$.next(this._config);
  }

  // validate that all categories are defined
  private cleanData() {
    return this.data.filter((score) => typeof score === 'number');
  }
}
