import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { localizeNumber } from '../../utils/digits-info';

// localizes a number
@Pipe({
  name: 'localizedPercent',
  pure: false,
  standalone: false,
})
export class LocalizedPercentPipe implements PipeTransform, OnDestroy {
  private langSubscription: Subscription;

  constructor(
    @Optional() private translate: TranslateService,
    private cdf: ChangeDetectorRef,
  ) {
    if (translate) {
      // no cleanup, alive for the whole session
      this.langSubscription = translate.onLangChange.subscribe(() => {
        this.cdf.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  transform(value: number, digitsInfo?: string): string {
    const lang = (this.translate && this.translate.currentLang) || 'en';
    return localizeNumber(value, lang, digitsInfo, { style: 'percent' });
  }
}
