import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserService, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '../store/viewer-core-store.facade';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // IMPORTANT: This is the unique identifier sent from the backend, indicating
  // that this particular 401 is returned as a result of federated login
  private readonly FEDERATED_LOGIN_ERROR_ID = 'VIEWER_AUTH_REJECTED';
  private readonly SSO_FEDERATED_LOGIN_ERROR_ID = 'SSO_VIEWER_AUTH_REJECTED';

  constructor(
    private viewerCoreFacade: ViewerCoreFacade,
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private browser: BrowserService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          const extras = this.redirectUrl ? { queryParams: { redirectUrl: this.redirectUrl } } : {};
          if ([this.FEDERATED_LOGIN_ERROR_ID, this.SSO_FEDERATED_LOGIN_ERROR_ID].includes(err?.error?.message)) {
            if (err.error.message === this.FEDERATED_LOGIN_ERROR_ID && this.isNativeApp) {
              this.viewerCoreFacade.resetNative({ error: err, source: 'authIntercept' });
            }

            this.router.navigate(['/login'], extras);
            this.viewerCoreFacade.resetAppContext();
            return EMPTY;
          }
        }
        return throwError(err);
      }),
    );
  }

  get redirectUrl() {
    const pathname = this.window.location.pathname;
    const url = this.window.location.href;
    // if current path is an error page we do not redirect
    return pathname.indexOf('/error') === 0 ? '' : url;
  }

  get isNativeApp() {
    return this.browser.isNativeAppCookieSet();
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
