import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ao-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('state', [
      state(
        'initial, void, hidden',
        style({
          transform: 'scale(0.5)',
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          transform: 'scale(1)',
          opacity: 1,
        }),
      ),
      transition('* => visible', animate('150ms cubic-bezier(0.895, 0.03, 0.685, 0.22)')),
      transition('* => void', animate('100ms cubic-bezier(0.895, 0.03, 0.685, 0.22)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TooltipComponent implements OnDestroy {
  @ViewChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;
  @Input() narrow = false;

  @Input() padding: 'none' | 'tiny' | 'small' | 'less' = 'tiny';

  // not used within this component but accessed from tooltip.directive.ts so leave it alone.
  @Input() getWidth: () => number;

  id: string;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  mouseenter: Subject<void> = new Subject<void>();
  mouseleave: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.close.complete();
    this.mouseenter.complete();
    this.mouseleave.complete();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopPropagation();
  }

  closeTooltip() {
    this.close.emit();
  }

  onMouseEnter() {
    this.mouseenter.next();
  }

  onMouseLeave() {
    this.mouseleave.next();
  }
}
