import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentFile } from '@ao/shared-data-models';
import { BrowserService } from '@ao/utilities';

@Component({
  selector: 'ao-download-uploaded-file',
  templateUrl: './download-uploaded-file.component.html',
  styleUrls: ['./download-uploaded-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DownloadUploadedFileComponent {
  @Input() file: AttachmentFile;

  isNativeApp = false;

  @Output()
  nativeFileDownload = new EventEmitter<string>();

  constructor(private browser: BrowserService) {
    this.isNativeApp = this.browser.isNativeAppCookieSet();
  }

  nativeAppDownloadFile(fileUrl: string) {
    this.nativeFileDownload.emit(fileUrl);
  }
}
