import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, input, output, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonUiModule } from '@ao/common-ui';

@Component({
  selector: 'ao-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  imports: [CommonModule, CommonUiModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent {
  @Input() showBackButton = true;
  @Input() autofocus = false;
  @Output() searched = new EventEmitter<string>();
  @Output() backClicked = new EventEmitter<void>();

  searchText = input<string>('');
  searchTextChange = output();
}
