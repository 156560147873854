import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ACTIMO_COLORS, AppButtonColor, BrandingColor, MessageListAcademyData } from '@ao/data-models';

@Component({
  selector: 'ao-academy-inline-progress',
  templateUrl: './academy-inline-progress.component.html',
  styleUrl: './academy-inline-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AcademyInlineProgressComponent {
  @Input() themedContent = true;
  @Input() themedColor: string;
  @Input() showPercentage = false;
  @Input() displayButton = true;
  @Input() showCompletionState = false;
  @Input() set academyData(value: MessageListAcademyData) {
    this.progress = value.progress;
    this.trainingsPassed = value.trainingsPassed;
    this.trainings = value.trainings;
    this.showPercentageBar = !!value.progress;
  }

  readonly defaultAcademyThemeColor: BrandingColor = ACTIMO_COLORS.green.base as BrandingColor;
  readonly notInitiatedColor: BrandingColor = ACTIMO_COLORS.ink.lighter as BrandingColor;
  readonly defaultButtonColor: AppButtonColor = 'blue';
  progress = 0;
  trainingsPassed: number;
  trainings: number;
  showPercentageBar = false;

  get colorValue() {
    return this.themedContent ? this.themedColor : this.defaultAcademyThemeColor;
  }
}
