import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { BrandingColor, IconSize } from '@ao/data-models';

@Component({
  selector: 'ao-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  providers: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IconButtonComponent {
  @HostBinding('class.ao-icon-button') className = true;

  @Input() iconName: string;
  @Input() toolTip?: string;
  @Input() disabled?: boolean;
  @Input() iconSize: IconSize = 20;
  @Input() iconColor?: BrandingColor | 'inkLighter';
  @Input() buttonBackground?: boolean;
  @Input() active?: boolean;

  @HostBinding('class.ao-icon-button--bg') get bgStyle() {
    return this.buttonBackground;
  }
  @HostBinding('class.ao-icon-button--active') get activeStyle() {
    return this.active;
  }
}
