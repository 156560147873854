import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { localizeNumber } from '@ao/utilities';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export const ANIMATION_DURATION = 1200; // ms

@Component({
  selector: 'ao-animated-number',
  templateUrl: './animated-number.component.html',
  styleUrls: ['./animated-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AnimatedNumberComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() value: number;
  @Input() digitsInfo: string;
  @Input() percent = false;
  @Input() animateOnLoad = true;

  private langSubscription: Subscription;
  private animationFrame = null;

  constructor(
    @Optional() private translate: TranslateService,
    private elementRef: ElementRef,
  ) {
    if (translate) {
      // no cleanup, alive for the whole session
      this.langSubscription = translate.onLangChange.subscribe(() => {
        this.setValue(this.value);
      });
    }
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.animateOnLoad) {
      this.animate(this.value);
    } else {
      this.setValue(this.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['value'];
    if (!change || change.firstChange) {
      // ignore, handled on ngAfterViewInit
    } else {
      this.animate(change.currentValue, change.previousValue);
    }
  }

  private animate(to, from = 0) {
    if (typeof to !== 'number' || Number.isNaN(to)) {
      this.setValue(to);
      return;
    }
    let start = null;
    const easing = (t) => --t * t * t + 1;
    const animateFn = (timestamp) => {
      if (!start) {
        start = timestamp;
      }
      const t = Math.min((timestamp - start) / ANIMATION_DURATION, 1); // t E [0, 1]
      this.setValue(from + (to - from) * easing(t));
      if (t < 1) {
        this.animationFrame = requestAnimationFrame(animateFn);
      } else {
        this.animationFrame = null;
      }
    };
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
    this.animationFrame = requestAnimationFrame(animateFn);
  }

  private setValue(value: number) {
    const lang = (this.translate && this.translate.currentLang) || 'en';
    const text = localizeNumber(value, lang, this.digitsInfo, { style: this.percent ? 'percent' : 'decimal' });
    (<HTMLElement>this.elementRef.nativeElement).innerText = text;
  }
}
