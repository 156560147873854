<ao-search-bar
  [autofocus]="!fromSearch"
  (searched)="onSearch($event)"
  [showBackButton]="isSmallScreen() ?? true"
  [searchText]="searchText()"
  (backClicked)="navigateToPreviousRoute()"
  id="searchFeatureBar"
/>

@if (
  !searchLoading() &&
  recentSearches()?.length &&
  (!searchText() || searchText().length < SEARCH_MIN_CHARS) &&
  !searchResults()?.length
) {
  <div class="results">
    <h4 class="ao-font-body2-bold">{{ 'RECENT SEARCHES' | translate }}</h4>
    <div class="recent-queries">
      @for (query of recentSearches(); track query) {
        <ao-search-recent-query
          [query]="query"
          (removed)="removeRecentQuery(query)"
          (quickSearched)="onSearch(query)"
        />
      }
    </div>
  </div>
} @else {
  <ao-infinite-scroll
    [minBufferPx]="360"
    [maxBufferPx]="700"
    [itemSize]="178"
    orientation="vertical"
    [items]="searchResults() ?? []"
    (scrolled)="onNextPage()"
    [scrollToId]="elementToScrollTo()"
  >
    <ng-template let-item let-index="index" #itemTemplate>
      <div class="ao-search-result-item" (click)="itemVisited(index)">
        <ao-search-result [item]="item" [query]="searchText()" (itemClicked)="itemClicked($event)" />
      </div>
    </ng-template>

    @if (searchLoading()) {
      <ng-container *ngFor="let i of [1, 2, 3]">
        <ng-template [ngTemplateOutlet]="skeletonMessageItem"></ng-template>
      </ng-container>
    }
    @if (!searchLoading() && searchText().length >= SEARCH_MIN_CHARS && searchResults()?.length === 0) {
      <ao-empty-state-item [type]="EmptyStateItemType.Search" />
    }
  </ao-infinite-scroll>
}

<!-- SKELETONS -->
<ng-template #skeletonMessageItem>
  <div class="ao-skeleton-item--message">
    <div class="ao-skeleton-item--message--group">
      <ao-tag class="ao-skeleton-item--message--label">&nbsp;</ao-tag>
      <ao-tag class="ao-skeleton-item--message--item">&nbsp;</ao-tag>
    </div>
    <ao-tag class="ao-skeleton-item--message--image">&nbsp;</ao-tag>
  </div>
  <div class="ao-skeleton-item--message--divider"></div>
</ng-template>
