import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[aoModalHeader]',
  standalone: false,
})
export class ModalHeaderDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[aoModalContent]',
  standalone: false,
})
export class ModalContentDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[aoModalFooter]',
  standalone: false,
})
export class ModalFooterDirective {
  constructor(public template: TemplateRef<any>) {}
}
