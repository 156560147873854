import {
  Notification,
  NotificationChannelSettingType,
  NotificationRequestParams,
  NotificationSettingsGroup,
  NotificationSettingType,
  NotificationTab,
} from '@ao/shared-data-models';
import { createAction, props } from '@ngrx/store';

export const LoadNotificationHasUnread = createAction(
  '[Notifications] Load Notification Has Unread',
  props<{ hasUnread?: boolean }>(),
);
export const LoadNotificationHasUnreadSuccess = createAction(
  '[Notifications] Load Notification Has Unread Success',
  props<{ unreadCount: number }>(),
);

export const LoadNotifications = createAction(
  '[Notifications] Load Notifications',
  props<{ params: NotificationRequestParams; filterUnread: boolean; shouldLoadFresh: boolean }>(),
);

export const LoadMoreNotifications = createAction(
  '[Notifications] Load More Notifications',
  props<{ params: NotificationRequestParams; filterUnread: boolean }>(),
);
export const LoadMoreNotificationsSuccess = createAction(
  '[Notifications] Load More Notifications Success',
  props<{ notifications: Notification[]; filterUnread: boolean }>(),
);
export const LoadMoreNotificationsFail = createAction(
  '[Notifications] Load More Notifications Fail',
  props<{ error: any }>(),
);

export const LoadFreshNotifications = createAction(
  '[Notifications] Load Fresh Notifications',
  props<{ params: NotificationRequestParams; filterUnread: boolean }>(),
);
export const LoadFreshNotificationsSuccess = createAction(
  '[Notifications] Load Fresh Notifications Success',
  props<{ notifications: Notification[]; filterUnread: boolean; lastSeenNotifications: Date }>(),
);
export const LoadFreshNotificationsFail = createAction(
  '[Notifications] Load Fresh Notifications Fail',
  props<{ error: any }>(),
);

export const MarkNotificationAsRead = createAction(
  '[Notifications] Mark Notification As Read',
  props<{ notification: Notification }>(),
);
export const MarkNotificationAsReadSuccess = createAction(
  '[Notifications] Mark Notification As Read Success',
  props<{ notification: Notification; isRead: boolean }>(),
);
export const MarkNotificationAsReadFail = createAction(
  '[Notifications] Mark Notification As Read Fail',
  props<{ error: any }>(),
);

export const LoadUserNotificationSettings = createAction('[Notifications] Load User Notification Settings');
export const LoadUserNotificationSettingsSuccess = createAction(
  '[Notifications] Load User Notification Settings Success',
  props<{ settingsGroups: NotificationSettingsGroup[] }>(),
);
export const LoadUserNotificationSettingsFail = createAction(
  '[Notifications] Load User Notification Settings Fail',
  props<{ error: any }>(),
);

export const MarkNotificationAsUnread = createAction(
  '[Notifications] Mark Notification As Unread',
  props<{ notification: Notification }>(),
);
export const MarkNotificationAsUnreadSuccess = createAction(
  '[Notifications] Mark Notification As Unread Success',
  props<{ notification: Notification; isRead: boolean }>(),
);
export const MarkNotificationAsUnreadFail = createAction(
  '[Notifications] Mark Notification As Unread Fail',
  props<{ error: any }>(),
);

export const MarkAllNotificationsAsRead = createAction('[Notifications] Mark All Notifications As Read');
export const MarkAllNotificationsAsReadSuccess = createAction('[Notifications] Mark All Notifications As Read Success');
export const MarkAllNotificationsAsReadFail = createAction(
  '[Notifications] Mark All Notifications As Read Fail',
  props<{ error: any }>(),
);

export const ToggleNotificationSetting = createAction(
  '[Notifications] Toggle Notification Setting',
  props<{
    settingType: NotificationSettingType;
    channelSettingType: NotificationChannelSettingType;
    newValue: boolean;
  }>(),
);
export const ToggleNotificationSettingSuccess = createAction(
  '[Notifications] Toggle Notification Setting Success',
  props<{
    settingType: NotificationSettingType;
    channelSettingType: NotificationChannelSettingType;
    newValue: boolean;
  }>(),
);
export const ToggleNotificationSettingFail = createAction(
  '[Notifications] Toggle Notification Setting Fail',
  props<{ error: any }>(),
);

export const ToggleGroupNotificationSetting = createAction(
  '[Notifications] Toggle Group Notification Setting',
  props<{ groupId: string; newValue: boolean; source: string }>(),
);
export const ToggleGroupNotificationSettingSuccess = createAction(
  '[Notifications] Toggle Group Notification Setting Success',
  props<{ groupId: string }>(),
);
export const ToggleGroupNotificationSettingFail = createAction(
  '[Notifications] Toggle Group Notification Setting Fail',
  props<{ error: any }>(),
);

export const SwitchActiveTab = createAction(
  '[Notifications] Switch Active Tab',
  props<{ activeTab: NotificationTab }>(),
);
export const RegisterNotificationClick = createAction(
  '[Notifications] Register Notification Click',
  props<{ notification: Notification }>(),
); // for analytics

export const SetNotificationUnreadCount = createAction(
  '[Core] Set Notification Unread Count',
  props<{ unreadCount: number }>(),
);

export const SetNativeNotificationUnreadCountSuccess = createAction(
  '[Core] Set Native Notification Unread Count Success',
);
export const SetNativeNotificationUnreadCountFail = createAction(
  '[Core] Set Native Notification Unread Count Fail',
  props<{ error: string }>(),
);
