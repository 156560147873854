import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { fixProtocol } from '@ao/utilities';

// Note: this component is built with assumption of having static "sources" which will not be swapped out on the run
@Component({
  selector: 'ao-audio-waveform-player',
  templateUrl: './audio-waveform-player.component.html',
  styleUrls: ['./audio-waveform-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AudioWaveformPlayerComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.ao-audio-waveform-player') className = true;
  @HostBinding('class.ao-audio-waveform-player--with-background') @Input() hasBackground = false;
  @HostBinding('class.ao-audio-waveform-player--border') @Input() showBorder = false;
  @HostBinding('class.ao-audio-waveform-player--in-chat') @Input() inChat = false;

  private player: any;

  // inputs
  @Input() sources: any[] = [];
  @Input() blobUrl?: SafeUrl;
  @Input() customDuration?: number;
  @Input() autoplay = false;
  @Input() fixedWidth = false;

  _sourceDuration = 0;

  // element reference
  @ViewChild('target') target: ElementRef;

  get paused(): boolean {
    return !this.player || this.player.paused() === undefined || this.player.paused();
  }

  get duration(): number {
    return this.player && this.player.duration();
  }

  get currentTime(): number {
    return this.player && this.player.currentTime();
  }

  set currentTime(value: number) {
    if (this.player) this.player.currentTime(value);
  }

  public play() {
    if (this.player) this.player.play();
  }
  public pause() {
    if (this.player) this.player.pause();
  }

  private get playerJsConfig(): any {
    const blobSources = this.blobUrl ? [{ type: 'audio/wav', src: this.blobUrl }] : null;
    return {
      sources: blobSources || this.sources.map(({ type, src }) => ({ type, src: fixProtocol(src) })),
      nativeControlsForTouch: true,
      controlBar: { fullscreenToggle: false },
      playbackRates: [0.5, 1, 1.5, 2, 2.5, 3],
      controls: false,
    };
  }

  // specific to the waveform

  public setPlayHead(percentValue) {
    this.currentTime = ((this.duration || 0) / 100) * percentValue;
    if (this.paused) {
      this.play();
    }
  }
  get playHeadPercent() {
    return this.duration ? (this.currentTime / this.duration) * 100 : 0;
  }

  refreshPlayHeadTime() {
    // poke angular to timeline changes
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  backToBeginning() {
    this.currentTime = 0;
    // poke angular to timeline changes
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.loadPlayer();
  }

  ngOnDestroy() {
    this.unloadPlayer();
  }

  private async loadPlayer() {
    const videojs = await import('video.js/dist/alt/video.core.novtt.min.js');
    const domPlayer = document.createElement('audio');
    domPlayer.setAttribute('controls', 'controls');
    domPlayer.setAttribute('preload', 'auto');
    if (this.autoplay) {
      domPlayer.setAttribute('autoplay', '');
    }

    // (<HTMLElement>this.hiddenPlayerElemRef.nativeElement).appendChild(domPlayer);
    this.player = videojs.default(domPlayer, this.playerJsConfig, () => {
      // hook up player library events to angular Outputs
      this.player.on('timeupdate', () => this.refreshPlayHeadTime());
      this.player.on('ended', () => this.backToBeginning());
      this.player.on('pause', () => this.pause());

      this.player.on('error', () => {
        // should we handle errors somehow?
      });
      this.player.controls(false);
    });
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
    // generate duration
    if (this.sources.length) {
      this.player.on('loadedmetadata', () => {
        this._sourceDuration = this.player.duration();
        this.cdr.detectChanges();
      });
    }
  }

  private unloadPlayer() {
    try {
      this.player.off('timeupdate');
      this.player.off('ended');
      this.player.off('pause');
      this.player.dispose();
    } catch (e) {
      //
    }
  }
}
