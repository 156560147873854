import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ao-input-tag',
  templateUrl: './input-tag.component.html',
  styleUrls: ['./input-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputTagComponent {
  @HostBinding('class.ao-input-tag') className = true;
  @HostBinding('class.ao-input-tag--bold')
  @Input()
  boldTag = false;

  @HostBinding('class.ao-input-tag--yellow') get colorRed() {
    return this.tagColor === 'yellow';
  }

  @Input() type: 'optional' | 'required' | 'custom' = 'custom';
  @Input() tagColor?: string;
}
