import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationsEffects } from './+state/notifications-store.effects';
import * as fromNotifications from './+state/notifications-store.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromNotifications.NOTIFICATIONS_FEATURE_KEY, fromNotifications.reducer),
    EffectsModule.forFeature([NotificationsEffects]),
  ],
})
export class ViewerNotificationStoreModule {}
