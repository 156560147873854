import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ao-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MenuButtonComponent {
  @HostBinding('attr.role') role = 'button';
  @HostBinding('class.ao-menu-button') className = true;
  @HostBinding('class.ao-menu-button--unread')
  @Input()
  hasUnread = false;
}
