import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';

let nextId = 0;

const DELAYS = {
  fast: 100,
  normal: 200,
  medium: 500,
  slow: 1000,
};

/**
 * This directive will be placed in any element to open a tooltipComponent.
 * It will create a overlay with the tooltip and attach it to the element.
 * The tooltip will be placed bottom and if it doesn't fit, top
 */
@Directive({
  selector: '[aoTooltip]',
  exportAs: 'tooltip',
  standalone: false,
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  @HostBinding('attr.aria-describedby') id = 'ao-tooltip-' + nextId++;
  @HostBinding('attr.aria-haspopup') ariaHasPopup = true;
  @HostBinding('attr.aria-expanded') get visible() {
    return this._overlayRef && this._overlayRef.hasAttached();
  }
  @HostBinding('style.cursor')
  get style() {
    return this.tooltipEnabled ? this.tooltipCursor : null;
  }

  @Input('aoTooltip') tooltip: TooltipComponent;
  @Input() tooltipAlign: 'start' | 'center' | 'end' = 'center';
  @Input() tooltipEnabled = true;
  @Input() position: 'top' | 'bottom' = 'bottom';
  @Input() tooltipCursor = 'help';

  @Input() set delay(value: keyof typeof DELAYS | number) {
    if (typeof value === 'number') {
      this._delay = value;
    } else {
      this._delay = DELAYS[value] || DELAYS.fast;
    }
  }
  @Input() set closeDelay(value: keyof typeof DELAYS | number) {
    if (typeof value === 'number') {
      this._closeDelay = value;
    } else {
      this._closeDelay = DELAYS[value] || DELAYS.fast;
    }
  }

  get isOpen() {
    return this.overlayRef?.hasAttached();
  }

  private _delay: number = DELAYS.fast;
  private _closeDelay: number = DELAYS.fast;
  private _overlayRef: OverlayRef;
  private portal: TemplatePortal<any>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private removeListener: Function;
  private openTimeout: any = null;
  private closeTimeout: any = null;

  private get positionBottom(): ConnectedPosition {
    return {
      originX: this.tooltipAlign,
      originY: 'bottom',
      overlayX: this.tooltipAlign,
      overlayY: 'top',
      offsetY: 4,
      panelClass: 'ao-tooltip--bottom',
    };
  }

  private get positionTop(): ConnectedPosition {
    return {
      originX: this.tooltipAlign,
      originY: 'top',
      overlayX: this.tooltipAlign,
      overlayY: 'bottom',
      offsetY: -4,
      panelClass: 'ao-tooltip--top',
    };
  }

  // Static property to track the currently open tooltip, tracking it for touch events. Avoiding multiple tooltips opened at the same time
  private static currentTooltip: TooltipDirective | null = null;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @HostListener('mouseenter')
  @HostListener('focus')
  @HostListener('click', ['$event'])
  triggerIn(event?: Event) {
    if (!this.tooltipEnabled || this.isOpen) return;
    let immediate = false;
    if (event) {
      immediate = true;
    }
    this.openTooltip(immediate);
  }

  @HostListener('mouseleave')
  @HostListener('focusout')
  @HostListener('click')
  triggerOut() {
    if (this.isOpen || this.openTimeout) {
      this.closeTooltip();
    }
  }

  @HostListener('touchstart', ['$event'])
  triggerInTouch(event: TouchEvent) {
    if (!this.tooltipEnabled || this.isOpen) return;
    event.preventDefault(); // Prevent long-press behavior
    event.stopPropagation();
    this.openTooltip(true);

    // Close the previously opened tooltip (if any)
    if (TooltipDirective.currentTooltip && TooltipDirective.currentTooltip !== this) {
      TooltipDirective.currentTooltip.closeTooltip(true);
    }

    // Set this tooltip as the currently active one
    TooltipDirective.currentTooltip = this;
  }

  @HostListener('document:touchstart', ['$event'])
  closeOnTouchOutside(event: TouchEvent) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.closeTooltip(true); // Close tooltip immediately on outside touch
    }

    // Clear the currently active tooltip
    if (TooltipDirective.currentTooltip === this) {
      TooltipDirective.currentTooltip = null;
    }
  }

  ngAfterViewInit() {
    if (this.tooltip) {
      this.tooltip.id = this.id;

      const functionToCall = () => {
        this.tooltip.closeTooltip();
      };
      if (this.document.scrollingElement) {
        (this.document.scrollingElement as HTMLElement).addEventListener('keyup.esc', functionToCall);
        this.removeListener = () => {
          (this.document.scrollingElement as HTMLElement).removeEventListener('keyup.esc', functionToCall);
        };
      }

      this.tooltip.close.subscribe(() => {
        this.closeTooltip();
      });

      this.tooltip.mouseenter.subscribe(() => {
        this.cancelClose();
      });

      this.tooltip.mouseleave.subscribe(() => {
        this.closeTooltip();
      });
    }
  }

  ngOnDestroy() {
    this.closeTooltip();
    if (this.removeListener) {
      this.removeListener();
    }
    this._overlayRef?.dispose();
    nextId--;
  }

  openTooltip(immediate = false) {
    this.cancelClose();
    if (!this.openTimeout || immediate) {
      this.openTimeout = setTimeout(
        () => {
          this.openTimeout = null;
          if (
            this.overlayRef &&
            !this.overlayRef.hasAttached() &&
            this.document.body.contains(<HTMLElement>this.elementRef.nativeElement)
          ) {
            this.overlayRef.attach(this.portal);
          }
        },
        immediate ? 0 : this._delay,
      );
    }
  }

  private cancelClose() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
    }
  }

  closeTooltip(immediate = false) {
    if (this.openTimeout) {
      clearTimeout(this.openTimeout);
      this.openTimeout = null;
    }
    if (this.isOpen && (!this.closeTimeout || immediate)) {
      this.closeTimeout = setTimeout(
        () => {
          this.closeTimeout = null;
          if (this.isOpen) {
            this.tooltip.close.emit();
            this.overlayRef.detach();
            // Clear the static reference when this tooltip is closed
            if (TooltipDirective.currentTooltip === this) {
              TooltipDirective.currentTooltip = null;
            }
          }
        },
        immediate ? 0 : this._closeDelay,
      );
    }
  }

  private get overlayRef(): OverlayRef {
    if (!this._overlayRef && this.tooltip) {
      // A template Portal is a reference to the ng-template placed in the template
      this.portal = new TemplatePortal(this.tooltip.templateRef, this.viewContainerRef);
      // Each OverlayCDK has a default conf but we want to override it
      const overlayState = new OverlayConfig();

      // positionate bottom first, top as fallback
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(
          this.position === 'top' ? [this.positionTop, this.positionBottom] : [this.positionBottom, this.positionTop],
        );
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      // hack into the position strategy so that we can add a tooltip tip and a nicer tansform-origin
      // eslint-disable-next-line @typescript-eslint/ban-types
      const _apply: Function = positionStrategy['apply'];
      positionStrategy['apply'] = function () {
        const tooltip = <HTMLElement>this._pane.querySelector('.ao-tooltip');
        if (self.tooltip.getWidth) {
          const width = self.tooltip.getWidth();
          tooltip.style.width = width ? width + 'px' : 'auto';
        }
        // eslint-disable-next-line prefer-rest-params
        _apply.apply(this, arguments);
      };
      // eslint-disable-next-line @typescript-eslint/ban-types
      const _applyPosition: Function = positionStrategy['_applyPosition'];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      positionStrategy['_applyPosition'] = function (origin, position) {
        // eslint-disable-next-line prefer-rest-params
        _applyPosition.apply(this, arguments);
        const originWidth = (<HTMLElement>self.elementRef.nativeElement).offsetWidth;
        const tip = this._pane.querySelector('.ao-tooltip__tip-wrapper');
        if (!tip) {
          return;
        }
        const tooltip = <HTMLElement>this._pane.querySelector('.ao-tooltip');
        switch (self.tooltipAlign) {
          case 'start':
            tip.style.left = coerceCssPixelValue(originWidth / 2);
            tooltip.style['transform-origin'] = `${coerceCssPixelValue(originWidth / 2)} ${
              origin.originY === 'bottom' ? 'top' : 'bottom'
            }`;
            break;
          case 'center':
            tip.style.left = '50%';
            tooltip.style['transform-origin'] = `50% ${origin.originY === 'bottom' ? 'top' : 'bottom'}`;
            break;
          case 'end':
            tip.style.right = coerceCssPixelValue(originWidth / 2);
            tooltip.style['transform-origin'] = `calc(100% - ${coerceCssPixelValue(originWidth / 2)}) ${
              origin.originY === 'bottom' ? 'top' : 'bottom'
            }`;
            break;
        }
      };

      overlayState.positionStrategy = positionStrategy;

      overlayState.scrollStrategy = this.overlay.scrollStrategies.close();
      overlayState.scrollStrategy.enable();

      this._overlayRef = this.overlay.create(overlayState);
    }
    return this._overlayRef;
  }
}
