import { Injectable } from '@angular/core';
import { FeatureFlags } from '@ao/data-models';
import { BehaviorSubject } from 'rxjs';

// will provide an easy way to access feature flags from within any component
// we can access flags in controllers through this.featureFlagService.featureFlags$.pipe(takeUntil(this.destroy$), pluck(key))
// to hide an element if the flags is false - use a directive: either `[aoFeatureFlag]='“feat_name'“` or  `[aoReleaseFlag]='“feat_name'“` ) - note that the 2 do exactly the same thing, the intent behind aoReleaseFlag is to make it easier to search / spot and clean up release flags
@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  // makes a local copy of feature flags here while bypassing the stores for easy access across the project
  featureFlags = new BehaviorSubject<FeatureFlags>(null);
  featureFlags$ = this.featureFlags.asObservable();

  // this should be called when ever feature flags are set or updated for the current user
  setFeatureFlags(value: FeatureFlags) {
    this.featureFlags.next(value);
  }
}
