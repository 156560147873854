import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipientTaskListItem } from '@ao/shared-data-models';
import { dateIsPassed, dateIsToday, onceWithLatest, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { TaskEntity, ViewerTaskFacade } from '@ao/viewer-task-management-store';
import { ViewerTrackingService } from '@ao/viewer-tracking-store';
import { map, Observable, of, switchMap, withLatestFrom } from 'rxjs';

const TASK_LIST_PAGE_SIZE = 50;
@Component({
  selector: 'ao-task-list-page',
  templateUrl: './task-list-page.component.html',
  styleUrls: ['./task-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TaskListPageComponent implements OnInit {
  router = inject(Router);
  routes = inject(ActivatedRoute);
  viewerCore = inject(ViewerCoreFacade);
  viewerTaskFacade = inject(ViewerTaskFacade);
  viewerTrackingService = inject(ViewerTrackingService);
  window = inject(WINDOW);

  activeTab: 'open' | 'completed' = this.routes.snapshot.data['activeTab'] === 'completed' ? 'completed' : 'open';

  tasks$ = this.viewerTaskFacade.tasks$;
  tasksIsLoading$ = this.viewerTaskFacade.tasksIsLoading$;
  paginationTotalCount$ = this.viewerTaskFacade.paginationTotalCount$;

  openSections: string[] = [];

  contactId$ = this.viewerCore.contactId$;

  completedTasks$: Observable<ReadonlyArray<RecipientTaskListItem & { completeByOther: boolean; lastUpdated?: Date }>> =
    this.tasks$.pipe(
      switchMap((tasks) => of(tasks.filter((task) => task.status === 'completed'))),
      withLatestFrom(this.contactId$),
      map(([t, contactId]) => {
        return t.map((task) => {
          return {
            ...task,
            completeByOther: task.completedByContactId && task.completedByContactId !== contactId ? true : false,
            lastUpdated: task.lastStatusUpdatedAt ? new Date(task.lastStatusUpdatedAt) : undefined,
          };
        });
      }),
    );
  uncompletedTasks$ = this.tasks$.pipe(switchMap((tasks) => of(tasks.filter((task) => task.status !== 'completed'))));
  uncompletedTaskSections$ = this.viewerTaskFacade.uncompletedTaskSections$;
  get isIsolatedRoute() {
    return this.router.url === '/task';
  }

  hasUnloadedTasks$ = this.tasks$.pipe(
    switchMap((tasks) => {
      return this.viewerTaskFacade.paginationTotalCount$.pipe(
        map((count) => {
          return tasks.length && count ? tasks.length < count : false;
        }),
      );
    }),
  );

  _showConfirmReopen = false;
  _confirmationTaskId?: number = undefined;
  _confirmationTaskType: 'oneToOne' | 'oneToMany' = 'oneToOne';
  _pageNumber = 0;
  _pageSortyBy = 'dueDate';
  _orderDir = 'asc';

  ngOnInit(): void {
    this.fetchTasks();
  }

  fetchTasks(option?: { page: number }) {
    this._pageNumber = option?.page || 0;
    const payload = {
      pageSize: TASK_LIST_PAGE_SIZE,
      page: this._pageNumber,
      status: this.activeTab,
      sortBy: this._pageSortyBy,
      orderDir: this._orderDir,
    };
    if (payload.page === 0) {
      this.viewerTaskFacade.fetchTasks(payload);
    } else {
      this.viewerTaskFacade.fetchMoreTasks(payload);
    }
  }

  fetchMoreTasks() {
    this._pageNumber++;
    this.fetchTasks({ page: this._pageNumber });
  }

  setActiveTab(tab: 'open' | 'completed') {
    this.activeTab = tab;
    if (tab === 'completed') {
      this._pageSortyBy = 'lastStatusUpdatedAt';
      this._orderDir = 'desc';
      this.fetchTasks();
    } else {
      this._pageSortyBy = 'dueDate';
      this._orderDir = 'asc';
      this.fetchTasks();
    }
  }

  uncompleteTaskPrompt(task: TaskEntity) {
    this._confirmationTaskId = task.id;
    this._confirmationTaskType = task.completionType;
    this._showConfirmReopen = true;
  }
  uncompleteTaskConfirmation() {
    if (this._confirmationTaskId) {
      this.viewerTaskFacade.uncompleteTask(this._confirmationTaskId);
      this._confirmationTaskId = undefined;
    }
    this._showConfirmReopen = false;
  }
  dismissUncomplete() {
    this._showConfirmReopen = false;
  }

  toggleSectionOpen(id: string) {
    if (this.openSections.includes(id)) {
      this.openSections = this.openSections.filter((sectionId) => sectionId !== id);
    } else {
      this.openSections.push(id);
    }
  }
  isSectionOpen(id: string) {
    return !this.openSections.includes(id);
  }

  goHome() {
    this.window.location.href = '/'; // do this now to rely on the server to redirect to correct homepage keycode for the user
  }
  goToTask(task: TaskEntity) {
    if (this.isIsolatedRoute) {
      this.router.navigate(['/task', task.id]);
    } else {
      onceWithLatest(this.viewerCore.origin$, this.viewerCore.keycode$, (origin, keycode) => {
        this.router.navigate(['/', origin, keycode, 'task', task.id]);
      });
    }
  }

  getPrioAsNumber(task: TaskEntity) {
    switch (task.priority) {
      case 'PRIORITY_1':
        return 1;
      case 'PRIORITY_2':
        return 2;
      case 'PRIORITY_3':
        return 3;
    }
    return undefined;
  }
  getUrgency(task: TaskEntity) {
    if (task.dueDate && dateIsPassed(task.dueDate)) {
      return 'high';
    }
    if (task.dueDate && dateIsToday(task.dueDate)) {
      return 'medium';
    }
    return 'low';
  }

  toggleTaskCompletion(task: TaskEntity) {
    this.viewerTrackingService.taskCompleted(task.id, 'overview');
    this.viewerTaskFacade.completeTask(task.id);
  }
  isTaskUpdating$(taskId: number) {
    return this.viewerTaskFacade.selectTaskUpdatingById$(taskId);
  }
}
