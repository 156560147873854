import { Action, createReducer, on } from '@ngrx/store';
import * as chatActions from './chat-store.actions';
import { ChatMessageReactions, CometchatNotificationSettings, CometChatUser } from '@ao/data-models';

export const CHAT_FEATURE_KEY = 'chat';

export interface ChatState {
  chatHasUnread: boolean;
  selectedMessageReactions: ChatMessageReactions;
  reactionsModalOpened: boolean;
  currentConversationAdmins?: CometChat.GroupMember[];
  loggedInUser?: CometChatUser;
  notificationSettings: CometchatNotificationSettings;
}

export const initialState: ChatState = {
  chatHasUnread: false,
  selectedMessageReactions: { reactions: [], totalReactions: 0 },
  reactionsModalOpened: false,
  currentConversationAdmins: undefined,
  loggedInUser: undefined,
  notificationSettings: {
    loading: true,
    dnd: false,
    chat: undefined,
    call: undefined,
  },
};

export interface ChatPartialState {
  readonly [CHAT_FEATURE_KEY]: ChatState;
}

const chatReducer = createReducer(
  initialState,
  on(chatActions.UpdateChatHasUnreadSuccess, (state: ChatState, { hasUnread }) => {
    return {
      ...state,
      chatHasUnread: hasUnread,
    };
  }),
  on(chatActions.openMessageReactions, (state: ChatState) => {
    return {
      ...state,
      reactionsModalOpened: true,
    };
  }),
  on(chatActions.closeMessageReactions, (state: ChatState) => {
    return {
      ...state,
      reactionsModalOpened: false,
    };
  }),
  on(chatActions.setSelectedMessageReations, (state: ChatState, { selectedMessageReactions }) => {
    return {
      ...state,
      selectedMessageReactions,
    };
  }),
  on(chatActions.resetSelectedMessageReactions, (state: ChatState) => {
    return {
      ...state,
      selectedMessageReactions: initialState.selectedMessageReactions,
    };
  }),
  on(chatActions.fetchCurrentConversationAdminsSuccess, (state: ChatState, { currentConversationAdmins }) => {
    return {
      ...state,
      currentConversationAdmins,
    };
  }),
  on(chatActions.resetCurrentConversationAdmin, (state: ChatState) => {
    return {
      ...state,
      currentConversationAdmins: undefined,
    };
  }),
  on(chatActions.fetchLoggedInUserSuccess, (state: ChatState, { loggedInUser }) => {
    return {
      ...state,
      loggedInUser,
    };
  }),
  on(chatActions.fetchNotificationSettingsSucces, (state: ChatState, { notificationSettings }) => {
    return {
      ...state,
      notificationSettings: { ...notificationSettings, loading: false },
    };
  }),
  on(chatActions.toggleCometchatNotifications, (state: ChatState) => {
    return {
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        loading: true,
      },
    };
  }),
  on(chatActions.toggleCometchatNotificationsSuccess, (state: ChatState) => {
    return {
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        dnd: !state.notificationSettings.dnd,
        loading: false,
      },
    };
  }),
  on(chatActions.toggleCometchatNotificationsFail, (state: ChatState) => {
    return {
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        loading: false,
      },
    };
  }),
);

export function reducer(state: ChatState, action: Action) {
  return chatReducer(state, action);
}
