import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ThemeHandlerService } from '@ao/common-ui';
import { Contact, Sidebar, Theme } from '@ao/data-models';
import { NavPages, color as c } from '@ao/utilities';

@Component({
  selector: 'ao-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HeaderComponent implements OnChanges {
  @HostBinding('class.ao-header') className = true;
  @Input() showBackButton = false;
  @Input() sidebar: Sidebar = null;
  @Input() showMenuButton: boolean;
  @Input() hasSidebarUnread = false;
  @Input() hasNotificationsUnread = false;
  @Input() contact: Contact = null;
  @Input() contactInitials: string = null;
  @Input() chatLink: string[] = null;
  @Input() searchLink: string[] = null;
  @Input() socialLink: string[] = null;
  @Input() profileLink: string[] = null;
  @Input() notificationsLink: string[] = null;
  @Input() chatHasUnreadMessages = false;
  @Input() isSmallScreen = true;
  @Input() page: NavPages = null;
  @Input() disabledIcons = false;
  @Input() hiddenIcons = false;
  @Input() logoClickDisabled = false;
  @Input() theme: Theme;

  @Output() backClick = new EventEmitter<void>();
  @Output() homePageClick = new EventEmitter<Event>();
  @Output() sidebarToggle = new EventEmitter<void>();
  @Output() profileClicked = new EventEmitter<string[]>();
  @Output() chatClick = new EventEmitter<string[]>();
  @Output() searchClick = new EventEmitter<string[]>();
  @Output() socialClick = new EventEmitter<string[]>();
  @Output() notificationsClick = new EventEmitter<string[]>();

  // nav-bar
  @Input() showSocialInNavbar: boolean;

  get logo() {
    if (this.theme?.nav_logo_images?.length || this.theme?.nav_logo_url) {
      return this.themeHandler.getBackgroundImageByAspectRatio(
        this.theme.nav_logo_images,
        320,
        100,
        this.theme.nav_logo_url,
      );
    } else {
      return '/assets/logo@2x.png';
    }
  }

  constructor(
    private themeHandler: ThemeHandlerService,
    private cdr: ChangeDetectorRef,
  ) {
    this.registerHeaderStyles();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSmallScreen) {
      this.registerHeaderStyles();
      this.themeHandler.createStylesheet();
    }
  }

  registerHeaderStyles() {
    this.themeHandler.registerStyle('HeaderComponent', (theme) => {
      this.theme = theme;
      this.cdr.markForCheck();

      const hideHeaderBgImage = `
        .ao-header {
          background-image: none !important;
        }`;

      return `
      .ao-header {
        background-color: ${c(theme.nav_bg_color, 'transparent')};
        color: ${c(theme.nav_icon_highlight_color, '#2a2a2a')};
        ${
          theme.nav_bg_images.length || theme.nav_bg_image_url
            ? `
            ${this.themeHandler.getBackgroundImageByWidth(theme.nav_bg_images, theme.nav_bg_image_url)}
          background-position: center;
          background-size: cover;
        `
            : ''
        }
      }
      .ao-header .ao-base-header__close {
        color: ${c(theme.nav_icon_color, '#ffffff')} !important;
      }
      .ao-header__button {
        color: ${c(theme.nav_icon_color, '#ffffff')} !important;
      }
      .ao-header__chat-icon {
        color: ${c(
          `${this.theme.nav_icon_color.startsWith('#') ? '' : '#'}${this.theme.nav_icon_color}`,
          '#ffffff',
        )} !important;
      }
      .ao-header__chat-icon--highlight {
        color: ${c(
          `${this.theme.nav_icon_highlight_color.startsWith('#') ? '' : '#'}${this.theme.nav_icon_highlight_color}`,
          '#ffffff',
        )} !important;
      }
      .ao-header__button-social-wall--active:after {
        background-color: ${c(theme.nav_icon_color, '#ffffff')};
      }

      @media only screen and (min-width: 992px) {
        ${!this.isSmallScreen ? hideHeaderBgImage : ''}
      }
      `;
    });
  }
}
