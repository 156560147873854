import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact, IconSize, Theme } from '@ao/data-models';
import { NavPages } from '@ao/utilities';

@Component({
  selector: 'ao-nav-bar-content',
  templateUrl: './nav-bar-content.component.html',
  styleUrls: ['./nav-bar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NavBarContentComponent {
  @Input() currentMessageId: number;
  @Input() theme: Partial<Theme>;
  @Input() contact?: Contact = null;
  @Input() profileLink?: string[];
  @Input() showSocialInNavbar: boolean;
  @Input() socialLink?: string[];
  @Input() showTasksInNavBar: boolean;
  @Input() tasksLink?: string[];
  @Input() tasksBadge = false;
  @Input() notificationsLink: string[] = null;
  @Input() hasNotificationsUnread = false;
  @Input() iconSize: IconSize = 28;
  @Input() disabledIcons = false;
  @Input() page: NavPages;
  @Input() url: string;
  @Output() profileClicked = new EventEmitter<string[]>();
  @Output() socialClick = new EventEmitter<string[]>();
  @Output() notificationsClick = new EventEmitter<string[]>();
  @Output() homePageClick = new EventEmitter<Event>();
  @Output() tasksClick = new EventEmitter<string[]>();

  get hasAvatarImages(): boolean {
    return !!(this.contact?.avatar_url || this.contact?.avatar_media_file_id || this.contact?.avatarImages?.length > 0);
  }

  get isHomePage(): boolean {
    return this.theme?.nav_click_target === this.currentMessageId;
  }

  isRootLevel(rootLevel: NavPages): boolean {
    const urlSegments = this.url.split('/');
    return this.page === 'PROFILE'
      ? urlSegments[urlSegments.length - 2] === rootLevel
      : urlSegments[urlSegments.length - 1] === rootLevel;
  }

  getColor(pageName: NavPages, isRootLevel?: boolean): string {
    return this.page === pageName &&
      (isRootLevel ? this.isRootLevel(pageName.toLowerCase() as NavPages) : this.isHomePage)
      ? '#' + this.theme?.nav_icon_highlight_color
      : '#' + this.theme?.nav_icon_color;
  }
}
