import { Injectable } from '@angular/core';
import { AoTrackEvent } from '@ao/data-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs/operators';
import { MessageActionHandler } from '../../services/message-action-handler.service';
import * as appActions from '../actions';

@Injectable()
export class ModuleConfirmEffects {
  constructor(
    private actions$: Actions,
    private messageActionHandler: MessageActionHandler,
  ) {}

  trackConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.Confirm),
      map(({ module }) => {
        return appActions.TrackEvent({ event: { key: `confirm:${module.id}`, event: 'confirmed', data: module } });
      }),
    ),
  );

  triggerButtonAction$ = createEffect(
    () =>
      this.actions$.pipe(
        delay(500), // allow the user some time to prepare for a possible transition
        ofType(appActions.TrackEvent),
        tap(({ type, event }) => this.handleActions([event], type)),
      ),
    { dispatch: false },
  );

  triggerButtonActionResults$ = createEffect(
    () =>
      this.actions$.pipe(
        delay(500), // allow the user some time to prepare for a possible transition
        ofType(appActions.TrackEventSuccess, appActions.TrackEventFail, appActions.TrackBulkEvents),
        tap(({ type, events, keycode }) => this.handleActions(events, type)),
      ),
    { dispatch: false },
  );

  handleActions(events: AoTrackEvent[], type: string) {
    const filteredEvents = events.filter(
      (eventData) => eventData.key.startsWith('confirm:') && eventData.event === 'confirmed' && eventData.data,
    );

    if (!filteredEvents.length) {
      return;
    }

    for (const event of filteredEvents) {
      if (
        (type !== appActions.TrackEvent.type && type !== appActions.TrackBulkEvents.type) ||
        this.messageActionHandler.shouldTriggerImmediately(event.data.actions)
      ) {
        this.messageActionHandler.trigger(event.data, 'confirm');
      }
    }
  }
}
