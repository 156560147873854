import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// service to enable asynchronous use of .focus() on mobile
// by first applying it to a placeholder input so it can later be shifted to the desired target
// try to place it closes to the source of your click element to reduce glitchy scrolling
@Injectable()
export class AsyncFocusService {
  private renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  focus() {
    // create the DOM element
    const focusPlaceholder = this.renderer.createElement('input');

    // hide it
    this.renderer.setStyle(focusPlaceholder, 'width', 0);
    this.renderer.setStyle(focusPlaceholder, 'height', 0);
    this.renderer.setStyle(focusPlaceholder, 'opacity', 1);

    // position it on the center of the screen to avoid auto scroll
    this.renderer.setStyle(focusPlaceholder, 'position', 'fixed');
    this.renderer.setStyle(focusPlaceholder, 'top', '50%');
    this.renderer.setStyle(focusPlaceholder, 'left', '50%');

    // add class for testing purpose
    this.renderer.addClass(focusPlaceholder, 'ao-async-focus');

    // avoid ios zoom
    this.renderer.setStyle(focusPlaceholder, 'fontSize', '16px');

    // Append to body
    this.renderer.appendChild(this.document.body, focusPlaceholder);

    // focus it
    focusPlaceholder.focus();

    // by now the real focus should have taken over, so clean it up.
    setTimeout(() => {
      this.renderer.removeChild(this.document.body, focusPlaceholder);
    }, 5000);
  }
}
