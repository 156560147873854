import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Directive({
  selector: '[aoLongPress]',
  standalone: false,
})
export class LongPressDirective implements OnDestroy {
  @Output() longPress = new EventEmitter<MouseEvent | TouchEvent>();
  @Output() shortPress = new EventEmitter<MouseEvent | TouchEvent>();

  private pressEvents = new Subject<MouseEvent | TouchEvent>();
  private longPressSubscription: Subscription;
  private readonly longPressDuration = 300; // milliseconds
  private longPressTriggered = false;

  constructor() {
    this.longPressSubscription = this.pressEvents
      .pipe(
        debounceTime(this.longPressDuration),
        filter((value) => !!value),
      )
      .subscribe((event) => {
        this.longPressTriggered = true;
        this.longPress.emit(event);
      });
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onPressStart(event: MouseEvent | TouchEvent): void {
    this.longPressTriggered = false;
    this.pressEvents.next(event);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent | TouchEvent): void {
    if (this.longPressTriggered) {
      event.preventDefault();
      this.longPressTriggered = false;
    } else {
      this.shortPress.emit(event);
    }
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  @HostListener('touchcancel')
  onPressEnd(): void {
    this.pressEvents.next(null);
  }

  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    // only prevent context menu if the element or parent has the long press directive
    // otherwise we might be preventing the context menu for the whole page
    const isChild = (event.target as HTMLElement)?.closest('[aoLongPress]');
    if (isChild) {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    if (this.longPressSubscription) {
      this.longPressSubscription.unsubscribe();
    }
  }
}
