import { Injectable } from '@angular/core';
import { UnreadStatus } from '@ao/data-models';
import { withLatestFromLazy } from '@ao/utilities';
import { CoreApiService, ViewerCoreFacade, viewerCoreActions } from '@ao/viewer-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, catchError, concatMap, map, of, switchMap } from 'rxjs';
import { ChatService } from '../services/chat-store.service';
import * as chatActions from './chat-store.actions';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ChatFacade } from '@ao/chat-store';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class ChatEffects {
  initChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerCoreActions.LoadViewerSettingsSuccess),
      withLatestFromLazy(this.viewerCoreFacade.featureFlagByKey$('chat'), this.viewerCoreFacade.chatLink$),
      switchMap(([{ settings }, isChatEnabled, chatlink]) => {
        if (isChatEnabled && settings?.chat && chatlink) {
          return this.coreApiService.initChat(settings.chat.chatAppId, settings.chat.chatAuthToken).pipe(
            map(() => chatActions.ChatInitSuccess()),
            catchError((error) => of(chatActions.ChatInitFail({ error }))),
          );
        }

        return EMPTY;
      }),
      switchMap((chatIsActive) => {
        return chatIsActive
          ? of(chatActions.UpdateChatHasUnread({ updateStatus: UnreadStatus.GET_FROM_SERVER }))
          : of(chatActions.UpdateChatHasUnread({ updateStatus: UnreadStatus.CHAT_NOT_ACTIVE }));
      }),
    ),
  );

  fetchLoggedInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.ChatOpened),
      concatMap((action) => this.chatService.getLoggedInUser()),
      map((loggedInUser) => chatActions.fetchLoggedInUserSuccess({ loggedInUser })),
    ),
  );

  loadChatHasUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.UpdateChatHasUnread),
      switchMap(({ updateStatus }) => {
        if (updateStatus === UnreadStatus.GET_FROM_SERVER) {
          return this.chatService.getInitialHasUnreadMessages().pipe(
            map((unreadFromServer) => {
              return chatActions.UpdateChatHasUnreadSuccess({ hasUnread: unreadFromServer });
            }),
            catchError((error) => of(chatActions.UpdateChatHasUnreadFail(error))),
          );
        }
        if (updateStatus === UnreadStatus.CHAT_NOT_ACTIVE) {
          return of(chatActions.UpdateChatHasUnreadFail({ error: updateStatus }));
        }
        if (updateStatus === UnreadStatus.NEW_UNREAD) {
          return of(chatActions.UpdateChatHasUnreadSuccess({ hasUnread: true }));
        }
        if (updateStatus === UnreadStatus.UP_TO_DATE) {
          return of(chatActions.UpdateChatHasUnreadSuccess({ hasUnread: false }));
        }

        return EMPTY;
      }),
    ),
  );

  fetchCurrentConversationAdmins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.fetchCurrentConversationAdmin),
      concatMap((action) => {
        return this.chatService.fetchConversationAdmins(action.groupID);
      }),
      map((currentConversationAdmins) =>
        chatActions.fetchCurrentConversationAdminsSuccess({ currentConversationAdmins }),
      ),
      catchError((error) => of(chatActions.fetchCurrentConversationAdminsFail(error))),
    ),
  );

  notificationSettings = toSignal(this.chatFacade.notificationSettings$, {
    initialValue: {
      dnd: false,
      loading: false,
    },
  });
  toggleCometchatNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.toggleCometchatNotifications),
      switchMap((action) =>
        this.chatService.toggleCometchatNotifications().pipe(
          map((response) => chatActions.toggleCometchatNotificationsSuccess()),
          catchError((error) => {
            if (error.code === 'ERR_TOKENS_NOT_REGISTERED') {
              this.viewerCoreFacade.showToast({
                listItemType: 'iconAvatar',
                iconName: 'warning',
                iconColor: 'red',
                title: this.translate.instant('Push notifications could not be #state#', {
                  state: this.notificationSettings().dnd ? 'enabled' : 'disabled',
                }),
                text: this.translate.instant('Please log in from a mobile device to #state# push notifications', {
                  state: this.notificationSettings().dnd ? 'enabled' : 'disabled',
                }),
                displayDuration: 4,
              });
            } else {
              this.viewerCoreFacade.showToast({
                listItemType: 'iconAvatar',
                iconName: 'warning',
                iconColor: 'red',
                title: this.translate.instant(i18n('Push notifications could not be #state#'), {
                  state: this.notificationSettings().dnd ? 'enabled' : 'disabled',
                }),
                text: i18n('Please try again'),
                displayDuration: 4,
              });
            }
            return of(chatActions.toggleCometchatNotificationsFail());
          }),
        ),
      ),
    ),
  );

  fetchNotificationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.UpdateChatHasUnreadSuccess),
      concatMap(() =>
        this.chatService.getCometchatPushNotificationStatus().pipe(
          map((notificationSettings) => chatActions.fetchNotificationSettingsSucces({ notificationSettings })),
          catchError((error) => of(chatActions.fetchNotificationSettingsFail(error))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private chatService: ChatService,
    private coreApiService: CoreApiService,
    private viewerCoreFacade: ViewerCoreFacade,
    private chatFacade: ChatFacade,
    private translate: TranslateService,
  ) {}
}
