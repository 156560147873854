import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

type bgColor = 'grey' | 'blue';
@Component({
  selector: 'ao-default-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DefaultErrorComponent {
  @HostBinding('class.ao-default-error') className = true;

  @Input() errorImageSrc?: string = '/assets/images/empty_state.svg';
  @Input() errorImageHeight?: string = '115px';
  @Input() bgColor?: bgColor = undefined;

  @Input() title?: string;
  @Input() description?: string;

  @HostBinding('class.ao-default-error--grey') get isGrey() {
    return this.bgColor === 'grey';
  }
}
