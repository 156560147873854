import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SkeletonItemType } from '@ao/data-models';

export type SkeletonItemTypeString = keyof typeof SkeletonItemType;

@Component({
  selector: 'ao-skeleton-block',
  templateUrl: './skeleton-block.component.html',
  styleUrls: ['./skeleton-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SkeletonBlockComponent {
  @HostBinding('class.ao-skeleton-block--fullWidth') @Input() fullWidth = false;

  @Input() heightStyle?: string;
  @Input() theme?: 'light' | 'dark';

  get classList() {
    return {
      'skeleton-block': true,
      'skeleton-block--fullWidth': this.fullWidth,
      'skeleton-block__themed': this.theme,
      'skeleton-block__themed--light': this.theme === 'light',
      'skeleton-block__themed--dark': this.theme === 'dark',
    };
  }
}
