import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ACTIMO_COLORS } from '@ao/data-models';
import { capitalize } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

dayjs.extend(localizedFormat);

// keep local reference to custom chart elements
const scatterChartElements = {
  targetArea: undefined,
  targetLineX: undefined,
  targetLineY: undefined,
  targetValueX: undefined,
  targetValueY: undefined,
  targetName: undefined,
  maxValue: undefined,
};

// colors for consistant use
const color = {
  lightGray: ACTIMO_COLORS.snow.light,
  gray: ACTIMO_COLORS.snow.dark,
  black: ACTIMO_COLORS.ink.base,
  green: ACTIMO_COLORS.green.base,
  white: '#ffffff',
  inkLight: ACTIMO_COLORS.ink.lightest,
};

// local strings to be passed into translation service (since they are applied within the controller)
let translatedTexts: string[] = [i18n('Target')];

// dayjs to be used for translations
const d = dayjs();

type ChartValue = number | number[];

@Component({
  selector: 'ao-scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScatterChartComponent implements OnInit, OnChanges {
  @HostBinding('class.ao-scatter-chart') className = true;

  @Input() target?: number | null = null;
  @Input() maxValue?: number = 9;
  @Input() data: ChartValue[];
  @Input() labels: string[];
  // if you want to show multiple values. pass in dates/score arrays for single value, pass date and and a single array for score.
  @Input() dates?: number[] | number;
  // axis translations are applied within the controller so pass them in
  @Input() translatedAxisNames?: string[];

  _translations: { id: string; name: string };
  _config: any = null;
  options$ = new ReplaySubject<any>(1);
  private chart: any;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    translatedTexts = [...(this.translatedAxisNames || []), ...translatedTexts];
    // translations that need to be rendered from within the controller
    this.translate
      .get(translatedTexts)
      .pipe(take(1))
      .subscribe((translatedValues) => {
        this._translations = translatedValues;
        if (!this._config && this.data) {
          // wait for the component to render, otherwise highcharts will take wrong dimensions
          setTimeout(() => this.buildHighcharts(), 0);
        }
      });
  }

  ngOnChanges(c: SimpleChanges) {
    // compare arrays
    const newChanges = JSON.stringify(c['data'].currentValue) !== JSON.stringify(c['data'].previousValue);

    if (this.chart && newChanges) {
      this.chart.series[0].setData(this.cleanData());
    }
  }

  onLoad(chart) {
    this.chart = chart;
    chart.reflow();
  }

  private buildHighcharts() {
    // translate default categories
    const translatedCategories = this.labels.map((category) => {
      const matchingCategory = translatedTexts[category] || category;
      return matchingCategory >= 0 ? this._translations[matchingCategory] : category;
    });

    const common = {
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        enabled: false,
        backgroundColor: ACTIMO_COLORS.blue.base,
        borderWidth: 0,
        headerFormat: '{point.key}',
        pointFormat: '',
        shadow: false,
        style: {
          color: color.white,
        },
      },
      plotOptions: {
        series: {
          color: color.black,
          lineWidth: 1,
        },
      },
      legend: {
        enabled: false,
        borderColor: 'transparent',
      },
    };

    // work around "this" inside functions
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _self = this;
    this._config = {
      ...common,
      chart: {
        backgroundColor: 'transparent',
        plotBorderColor: color.gray,
        plotBackgroundColor: _self.target ? color.lightGray : color.white,
        plotBorderWidth: 2,
        margin: 25,
        type: 'scatter',
        events: {
          render: function () {
            this.plotBorder.attr({ rx: 6, ry: 6 });
            const targetWidth = (this.plotBox.width / _self.maxValue) * (_self.maxValue - _self.target);
            const targetHeight = (this.plotBox.height / _self.maxValue) * (_self.maxValue - _self.target);

            // cleanup previous render
            for (const elem of Object.values(scatterChartElements)) {
              if (elem) {
                elem.destroy();
              }
            }

            // max value indicator on top right, "5" for padding
            scatterChartElements.maxValue = this.renderer
              .text(_self.maxValue, this.plotBox.x + this.plotBox.width + 4, this.plotBox.y - 4)
              .attr({
                fill: color.inkLight,
                fontSize: 12,
              })
              .add();

            if (_self.target) {
              // plotBox.x - width of where the left label is
              // this.plotBox.width - width of the chart grid
              const chartWidth = this.plotBox.x + this.plotBox.width;

              // target area highlight (x, y, width, height)
              scatterChartElements.targetArea = this.renderer
                .rect(chartWidth - targetWidth, this.plotBox.y, targetWidth, targetHeight, 1)
                .attr({
                  fill: color.white,
                  zIndex: 0,
                })
                .add();

              // horizontal target line (x, y, width, height)
              scatterChartElements.targetLineX = this.renderer
                .rect(this.plotBox.x, this.plotBox.y + targetHeight, this.plotBox.width, 2, 1)
                .attr({
                  fill: color.gray,
                  zIndex: 1,
                })
                .add();

              // vertical target line (x, y, width, height)
              scatterChartElements.targetLineY = this.renderer
                .rect(chartWidth - targetWidth, this.plotBox.y, 2, this.plotBox.height, 1)
                .attr({
                  fill: color.gray,
                  zIndex: 1,
                })
                .add();

              // numeric target value for x axis (number, x, y) "8" is for standard spacing
              scatterChartElements.targetValueX = this.renderer
                .text(_self.target, chartWidth - targetWidth, this.plotBox.y - 8)
                .attr({
                  fill: color.black,
                  fontSize: 12,
                })
                .add();

              // numeric target value for y axis (number, x, y) "8" is for standard spacing, "3" is to center the text
              scatterChartElements.targetValueY = this.renderer
                .text(_self.target, chartWidth + 8, this.plotBox.y + targetHeight + 3)
                .attr({
                  fill: color.black,
                  fontSize: 12,
                })
                .add();

              // "target" text (text, x, y) "4" for vertical align in relation to its height
              scatterChartElements.targetName = this.renderer
                .text(
                  _self._translations['Target'].toUpperCase(),
                  chartWidth - targetWidth / 2,
                  this.plotBox.y + targetHeight / 2 + 4,
                )
                .attr({
                  fill: color.inkLight,
                  fontSize: 16,
                  zIndex: 2,
                  align: 'center',
                })
                .add();
            }
          },
        },
      },
      yAxis: {
        min: 0,
        max: _self.maxValue,
        labels: {
          enabled: false,
        },
        tickLength: 1,
        gridLineWidth: _self.target ? 0 : 1,
        tickAmount: _self.maxValue + 1,
        title: {
          // Pull translation only if using the default name
          text: translatedCategories[1],
          style: { color: color.inkLight },
        },
      },
      xAxis: {
        min: 0,
        max: _self.maxValue,
        labels: {
          enabled: false,
        },
        tickLength: 1,
        lineWidth: 0,
        gridLineWidth: _self.target ? 0 : 1,
        tickAmount: _self.maxValue + 1,
        title: {
          // Pull translation only if using the default name
          text: translatedCategories[0],
          style: { color: color.inkLight },
        },
      },
      plotOptions: {
        series: {
          color: color.black,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          dataLabels: {
            align: 'center',
            enabled: true,
            crop: false,
            overflow: 'allow',
            allowOverlap: true,
            y: 11,
            style: {
              textOutline: false,
              fontWeight: 'normal',
            },
            formatter: function () {
              return this.point.shortName;
            },
          },
        },
      },
      series: [
        {
          data: this.cleanData(),
        },
      ],
    };
    // build is called from within subscribe which needs to poke change detection:
    this.options$.next(this._config);
  }

  zip(a, b) {
    const length = Math.max(a.length, b.length);
    const resultZip = [];
    for (let i = 0; i < length; i++) {
      resultZip.push([a[i], b[i]]);
    }
    return resultZip;
  }
  // validate that all categories are defined
  private cleanData() {
    // component takes array of dates/scores or a single date/score. unify output format here
    const dateArray = this.dates || [];
    const zipped = this.zip(dateArray, this.data);

    return zipped
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([date, score]: [number, ChartValue]) =>
          score && (typeof score === 'number' || score.every((s) => typeof s === 'number')),
      )
      .map(([date, score], i) => {
        let xValue = score[0];
        let yValue = score[1];
        const withinTarget = this.target && xValue >= this.target && yValue >= this.target;

        // In order to avoid overlapping of points we count how many points are "on top" so that we can move it a bit down and left
        const overlap = this.data.slice(i + 1).filter((s) => s[0] === score[0] && s[1] === score[1]).length;
        xValue = score[0] - 0.3 * overlap;
        yValue = score[1] - 0.3 * overlap;

        let shortName = '';
        if (date !== undefined && date !== null) {
          shortName = capitalize(d.month(+date).format('MMM'));
        } else {
          // if no dates defined, we show value as shows as 'X|Z'
          shortName = (score[0] || '-') + '|' + (score[1] || '-');
        }

        return {
          name: capitalize(d.month(+date).format('MMMM')),
          shortName: shortName,
          withinTarget: withinTarget,
          x: xValue,
          y: yValue,
          dataLabels: {
            color: color.black,
          },
          marker: {
            animation: {
              duration: 150,
            },
            fillColor: color.white,
            lineWidth: 2,
            lineColor: color.green,
            color: color.black,
            radius: shortName.length > 3 ? 20 : 15,
            states: {
              hover: {
                enabled: false,
              },
              normal: {},
            },
          },
        };
      });
  }
}
