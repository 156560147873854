import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { BrandingColor } from '@ao/data-models';

@Component({
  selector: 'ao-progress-linear',
  templateUrl: './progress-linear.component.html',
  styleUrls: ['./progress-linear.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgClass],
  standalone: false,
})
export class ProgressLinearComponent implements OnChanges {
  @Input() size = 'large';
  @Input() color: BrandingColor = 'blue';
  @Input() value = 0;

  @Input() target: number;
  @Input() targetLabel: string;
  @Input() valueName: string;

  @Input() maxValue = 100;

  get _hasTarget() {
    return typeof this.target === 'number';
  }

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.ngClass.ngClass = {
      'ao-progress-linear': true,
      'ao-progress-linear--has-target': this._hasTarget,
      [`ao-progress-linear--size-${this.size}`]: true,
    };
    this.ngClass.ngDoCheck();
  }
}
