import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Modal, ModalConfig } from '@ao/data-models';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'ao-modal-container',
  template: `
    <ng-template>
      <ng-content></ng-content>
    </ng-template>
  `,
  standalone: false,
})
export class ModalContainerComponent<U> {
  @Input() instance: Modal<U>;
  @Input() config: ModalConfig = {};

  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
  ) {}

  open() {
    const portal = new TemplatePortal<{ instance: Modal<U> }>(this.content, this.viewContainerRef, {
      instance: this.instance,
    });
    const instance = this.modalService.openWithPortal(portal, this.config);
    const modal = (<HTMLElement>instance.overlayRef.overlayElement.children.item(0)).getElementsByClassName(
      'ao-modal__wrapper',
    );
    if (modal.length) {
      setTimeout(() => (<HTMLElement>modal[0]).focus());
    }
    return instance;
  }
}
