import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ao-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SectionHeaderComponent {}
