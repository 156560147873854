import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ao-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SliderComponent), multi: true }],
  standalone: false,
})
export class SliderComponent implements ControlValueAccessor {
  @HostBinding('class.ao-slider') className = true;
  @HostBinding('class.ao-slider--disabled')
  @Input()
  disabled: boolean;
  inactive = true;
  @HostBinding('class.ao-slider--inactive') get inactiveClassName(): boolean {
    return this.inactive && !this.disabled;
  }

  @Input() min = 0;
  @Input() max = 10;
  @Input() step = 1;
  @Input() value: number = null;

  _setValue(value) {
    if (this.onModelChange) {
      this.inactive = false;
      this.onModelChange(value);
    }
  }

  get mid() {
    return this.min + Math.round((this.max - this.min) / this.step / 2) * this.step;
  }

  // when mouse down but don't move
  onMouseup() {
    if (this.inactive) {
      this._setValue(this.mid);
    }
  }

  // CONTROL VALUE ACCESSOR
  /* eslint-disable @typescript-eslint/member-ordering */
  private onTouch: any;
  private onModelChange: any;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  registerOnChange(fn: any) {
    this.onModelChange = fn;
  }
  writeValue(value: string) {
    if (typeof value === 'number' || typeof value === 'string') {
      this.value = +value;
      this.inactive = false;
    } else {
      // reset, set center
      this.inactive = true;
      this.value = this.mid;
    }
  }
}
