import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BrandingColor } from '@ao/data-models';

@Component({
  selector: 'ao-icon-box',
  templateUrl: './icon-box.component.html',
  styleUrls: ['./icon-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IconBoxComponent {
  @Input() iconName?: string;
  @Input() bgColor?: BrandingColor;
  @Input() isLoading = false;

  get classNames() {
    return {
      [`ao-icon-box--${this.bgColor}`]: this.bgColor,
      'ao-icon-box--hasColor': this.bgColor,
      'ao-icon-box--isLoading': this.isLoading,
    };
  }
}
