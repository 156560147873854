<form [formGroup]="_form" (ngSubmit)="continue($event)">
  <!-- avatar -->
  <ao-generic-page-header
    class="ao-update-contact-info__header"
    [avatarSize]="'biggest'"
    [title]="
      firstTimeFlow
        ? ('Confirm contact information' | translate)
        : phoneIntegration || emailIntegration
          ? ('Contact information' | translate)
          : ('Edit contact information' | translate)
    "
    [initials]="initials"
    [images]="avatarImages"
    [subTitle]="
      firstTimeFlow
        ? contactFirstName
          ? ('Hey there, #firstName#!' | translate: { firstName: contactFirstName })
          : ('Hey there!' | translate)
        : null
    "
    [type]="'avatar'"
  >
  </ao-generic-page-header>

  <!-- phone-->
  <ao-viewer-input-wrapper
    [label]="'Phone' | translate"
    [displayErrors]="_displayErrors"
    [required]="_msisdnOnlyKeyPolicy"
    [boldLabel]="true"
  >
    <ao-phone-input
      inputSize="medium"
      [hasError]="_displayErrors && _form.controls.msisdn.invalid"
      formControlName="msisdn"
      [defaultGeoIpCountryCode]="defaultCountryCode"
      name="phone"
    >
    </ao-phone-input>

    <!-- integration notice-->
    <ng-template aoViewerInputWrapperDisabledTemplate>
      <ng-container *ngIf="phoneIntegration">
        {{ 'Synced by integration, cannot be edited' | translate }}
      </ng-container>
    </ng-template>

    <!-- validation -->
    <ng-template aoViewerInputWrapperErrorTemplate>
      <ng-container *ngIf="!emailIntegration">
        <div *ngIf="_form['errors']?.atLeastOne && !_form['controls'].msisdn.invalid">
          {{ 'Please enter either a phone number or email' | translate }}
        </div>
        <div *ngIf="_form['controls'].msisdn.invalid">
          {{ 'Please enter a valid phone number' | translate }}
        </div>
      </ng-container>
    </ng-template>
  </ao-viewer-input-wrapper>

  <!-- email -->
  <ao-viewer-input-wrapper
    [label]="'Email' | translate"
    [displayErrors]="_displayErrors || _form.controls.email.invalid"
    [required]="_emailOnlyKeyPolicy"
    [boldLabel]="true"
  >
    <input
      ao-input
      inputSize="medium"
      [hasError]="_form.controls.email.invalid"
      type="email"
      formControlName="email"
      placeholder="No email address"
    />

    <!-- integration notice-->
    <ng-template aoViewerInputWrapperDisabledTemplate>
      <ng-container *ngIf="emailIntegration">
        {{ 'Synced by integration, cannot be edited' | translate }}
      </ng-container>
    </ng-template>

    <!-- validation -->
    <ng-template aoViewerInputWrapperErrorTemplate>
      <ng-container *ngIf="!emailIntegration">
        <div *ngIf="_form['errors']?.atLeastOne && !_form['controls'].email.invalid">
          {{ 'Please enter either a phone number or email' | translate }}
        </div>
        <div *ngIf="_form['controls'].email.invalid">
          {{ 'Please enter a valid email address' | translate }}
        </div>
      </ng-container>
    </ng-template>
  </ao-viewer-input-wrapper>

  <ao-viewer-input-wrapper
    [inlineInput]="true"
    [reverseOrder]="true"
    [displayErrors]="_displayErrors && !!_form.controls['termsOfUse'].invalid"
    *ngIf="!_form.controls['termsOfUse'].disabled"
  >
    <ao-checkbox
      [hasError]="_displayErrors && _form.controls.termsOfUse.invalid"
      [noMargin]="true"
      [smallCheckbox]="true"
      formControlName="termsOfUse"
    >
      <span>{{ 'I agree to the [terms of using the app]' | translate }} </span
      ><span
        class="ao-font-body2-bold ao-update-contact-info__terms-of-use-link"
        (click)="toggleTermsOfUse($event)"
        translate
        >[I agree to the] terms of using the app</span
      >
    </ao-checkbox>

    <!-- validation -->
    <ng-template aoViewerInputWrapperErrorTemplate>
      <p class="ao-update-contact-info__error">{{ 'Please also accept the terms to use the app' | translate }}</p>
    </ng-template>
  </ao-viewer-input-wrapper>

  <!-- hide contact details from others -->
  <ao-viewer-input-wrapper
    [label]="'Hide contact info' | translate"
    [inlineInput]="true"
    [fullWidthLabel]="true"
    *ngIf="!isManagerView"
  >
    <ao-toggle-slider formControlName="hideContactInfo"></ao-toggle-slider>
  </ao-viewer-input-wrapper>

  <!-- Employee directory info box -->
  <div class="ao-update-contact-info__box">
    <p translate>
      Turning on the “Hide your contact info” setting will hide your phone number and email when co-workers see your
      profile card in the app. The rest of the profile information is still visible, if it is added to your Profile.
    </p>
    <p class="ao-font-body2-bold ao-update-contact-info__box--title" translate>
      Profile information shown to co-workers
    </p>
    <ul class="ao-update-contact-info__box--list">
      <li class="ao-update-contact-info__box--list--item">
        <ao-icon class="ao-update-contact-info__box--list--icon" name="image" [size]="16"></ao-icon>
        <span translate>Profile picture</span>
      </li>
      <li class="ao-update-contact-info__box--list--item">
        <ao-icon class="ao-update-contact-info__box--list--icon" name="person" [size]="16"></ao-icon>
        <span translate>First/last name</span>
      </li>
      <li class="ao-update-contact-info__box--list--item">
        <ao-icon
          [ngClass]="{ 'ao-update-contact-info__box--list--icon--inactive': _form.value.hideContactInfo }"
          class="ao-update-contact-info__box--list--icon"
          name="phone"
          [size]="16"
        ></ao-icon>
        <span [ngClass]="{ 'ao-update-contact-info__box--list--text--inactive': _form.value.hideContactInfo }" translate
          >Phone number</span
        >
      </li>
      <li class="ao-update-contact-info__box--list--item">
        <ao-icon
          [ngClass]="{ 'ao-update-contact-info__box--list--icon--inactive': _form.value.hideContactInfo }"
          class="ao-update-contact-info__box--list--icon"
          name="alternate_email"
          [size]="16"
        ></ao-icon>
        <span [ngClass]="{ 'ao-update-contact-info__box--list--text--inactive': _form.value.hideContactInfo }" translate
          >Email address</span
        >
      </li>
      <li class="ao-update-contact-info__box--list--item">
        <ao-icon class="ao-update-contact-info__box--list--icon" name="business_center" [size]="16"></ao-icon>
        <span translate>Job title</span>
      </li>
      <li class="ao-update-contact-info__box--list--item">
        <ao-icon class="ao-update-contact-info__box--list--icon" name="business" [size]="16"></ao-icon>
        <span translate>Department</span>
      </li>
      <li class="ao-update-contact-info__box--list--item">
        <ao-icon class="ao-update-contact-info__box--list--icon" name="language" [size]="16"></ao-icon>
        <span translate>Country</span>
      </li>
    </ul>
  </div>

  <!-- send homepage -->
  <ao-viewer-input-wrapper
    [label]="'Send Link to Homepage' | translate"
    [inlineInput]="true"
    [fullWidthLabel]="true"
    *ngIf="!firstTimeFlow && isManagerView"
  >
    <ao-toggle-slider formControlName="sendHomePage"></ao-toggle-slider>
  </ao-viewer-input-wrapper>

  <!-- first time flow save button -->
  <button
    *ngIf="firstTimeFlow; else normalUpdate"
    type="submit"
    ao-button
    buttonColor="blue"
    [disabled]="_submitDebounce"
  >
    {{ phoneIntegration && emailIntegration ? ('Confirm and continue' | translate) : ('Continue' | translate) }}
  </button>

  <!-- normal update save button -->
  <ng-template #normalUpdate>
    <ng-container *ngIf="!_form.dirty; else updateButton">
      <button type="submit" ao-button buttonColor="blue" [outline]="true">{{ 'Close' | translate }}</button>
    </ng-container>
    <ng-template #updateButton>
      <button type="submit" ao-button buttonColor="blue" [disabled]="!_form.valid">{{ 'Save' | translate }}</button>
    </ng-template>
  </ng-template>
  <ng-container *ngIf="phoneIntegration || emailIntegration">
    <div class="ao-font-body2 ao-update-contact-info__footer-notice" translate>
      If your contact information is not correct
      <span
        class="ao-font-body2-bold ao-update-contact-info__support-link"
        (click)="toggleSupportModal.emit()"
        translate
        >contact your support</span
      >
      to get it changed
    </div>
  </ng-container>
</form>

<!-- Terms of use modal -->
<ao-message-modal-card
  [modalInsideModal]="true"
  [open]="_termsOfUseModalVisible"
  (close)="toggleTermsOfUse()"
  class="ao-update-contact-info__terms-of-use-modal"
>
  <h3 class="ao-font-title3 ao-update-contact-info__terms-of-use-header">{{ 'Terms of use' | translate }}</h3>
  <div class="ao-update-contact-info__terms-of-use-content" [innerHTML]="_TermsOfUseText | translate"></div>
  <button ao-button buttonColor="blue" (click)="acceptTermsOfUse()" translate>Accept</button>
</ao-message-modal-card>
