import { Injectable } from '@angular/core';
import * as appActions from '../actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppService } from '../../services/app-store.service';

@Injectable()
export class ModuleSmsVerifyEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
  ) {}

  submitSmsVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.SmsVerifySubmit),
      mergeMap(({ module, data: { phoneNumber, smsId } }) => {
        return this.appService.verifySms(phoneNumber, smsId).pipe(
          map((res) => appActions.SmsVerifySubmitSuccess({ module })),
          catchError((error) => of(appActions.SmsVerifySubmitFail({ module, error }))),
        );
      }),
    ),
  );
}
