import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ao-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  standalone: false,
})
export class LabelComponent {
  @HostBinding('class.ao-label') className = true;

  @Input() color: 'blue' | 'dark-blue' | 'yellow' | 'red' | 'ink' | 'green' = 'blue';
  @HostBinding('class.ao-label--dark-blue') get colorDarkBlue() {
    return this.color === 'dark-blue';
  }
  @HostBinding('class.ao-label--yellow') get colorLightYellow() {
    return this.color === 'yellow';
  }
  @HostBinding('class.ao-label--red') get colorLightRed() {
    return this.color === 'red';
  }
  @HostBinding('class.ao-label--ink') get colorLightInk() {
    return this.color === 'ink';
  }
  @HostBinding('class.ao-label--green') get colorLightGreen() {
    return this.color === 'green';
  }
}
