import { Directive, ElementRef, Input } from '@angular/core';
import { ImageSource, MediaItem } from '@ao/shared-data-models';

@Directive({
  selector: '[aoBackgroundImageArray]',
  standalone: false,
})
export class BackgroundImageArrayDirective {
  private el: HTMLElement;
  private backgroundUrl = '';
  private _images = [];
  private _fallbackUrl = '';
  private _minImageWidth = 300;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  @Input() coverImage = false;
  @Input() set minImageWidth(width: number) {
    this._minImageWidth = width || 300;
    this.setBackground();
  }

  @Input() set fallbackUrl(url: string) {
    this._fallbackUrl = url || '';
    this.setBackground();
  }

  @Input() set aoBackgroundImageArray(images: ImageSource[] | MediaItem[] | null) {
    this._images = images || [];
    this.setBackground();
  }

  setBackground() {
    let newUrl = this._fallbackUrl;
    // when uploading files, its better to avoid replacing the temporary blob so there is no flashing
    const isUpload = this._fallbackUrl.startsWith('blob:');
    if (!isUpload && this._images?.length) {
      const index = this._images.findIndex((image) => {
        return image?.width || image?.size?.width >= this._minImageWidth;
      });
      newUrl = index !== -1 ? this._images[index].url : this._images[0]?.url || newUrl;
    }

    if (newUrl !== this.backgroundUrl || this._images?.length === 0) {
      this.backgroundUrl = newUrl;
      this.el.style.backgroundImage = 'url(' + newUrl + ')';
      if (this.coverImage) {
        this.el.style.backgroundSize = 'cover';
      }
    }
  }
}
