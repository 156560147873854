import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Optional } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@ao/environments';
import { Observable } from 'rxjs';

@Injectable()
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export class DynamicMatIconRegistry extends MatIconRegistry {
  private _s: DomSanitizer;
  constructor(
    @Optional() _httpClient: HttpClient,
    _sanitizer: DomSanitizer,
    @Optional() @Inject(DOCUMENT) document: any,
    _errorHandler: ErrorHandler,
  ) {
    super(_httpClient, _sanitizer, document, _errorHandler);

    this._s = _sanitizer;
  }

  getNamedSvgIcon(name: string): Observable<SVGElement> {
    const [iconName, folder] = (name || '').split('/').slice(0, 2).reverse();
    return this.getSvgIconFromUrl(
      this._s.bypassSecurityTrustResourceUrl(
        `/assets/${folder || 'material-icons'}/${iconName}.svg?_=${environment.version}`,
      ),
    );
  }
}
