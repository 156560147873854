import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { environment } from '@ao/environments';
import { BrowserName, BrowserService, DatadogService } from '@ao/utilities';
import { TranslateService } from '@ngx-translate/core';
import * as PullToRefresh from 'pulltorefreshjs';
import { ViewerCoreFacade } from '../../store/viewer-core-store.facade';

@Component({
  selector: 'viewer-root',
  templateUrl: './root.component.html',
  standalone: false,
})
export class RootComponent implements OnInit {
  // Temporary fix to disable animation in problematic browsers
  // See issue: https://github.com/angular/angular/issues/24094
  @HostBinding('@.disabled')
  public disableAnimations = document.createElement('div').style['animation'] == null;

  constructor(
    translate: TranslateService,
    private browser: BrowserService,
    @Inject(DOCUMENT) private _document: any,
    private datadogService: DatadogService,
    private viewerCoreFacade: ViewerCoreFacade,
    private cdr: ChangeDetectorRef,
  ) {
    translate.setDefaultLang('en-US');
    translate.onLangChange.subscribe((lang) => {
      this._document.documentElement.lang = lang.lang?.split('-')[0];
      this.cdr.detectChanges();
    });
    if (this.browser.browserName() === BrowserName.Safari || this.browser.isNativeAppCookieSet()) {
      PullToRefresh.init({
        mainElement: 'body',
        onRefresh() {
          window.location.reload();
        },
        shouldPullToRefresh() {
          const wrapper = document.getElementsByTagName('mat-sidenav-container');
          if (
            document.body.classList.contains('ao-message-modal--open') ||
            (wrapper.length > 0 && wrapper[0].classList.contains('mat-drawer-container-has-open')) ||
            document.getElementsByClassName('pswp--open').length > 0 ||
            document.getElementsByTagName('ao-chat-ui').length > 0 ||
            document.getElementById('searchFeatureBar')
          ) {
            return false;
          }
          return !window.scrollY;
        },
      });
    }
  }

  ngOnInit(): void {
    if (environment.envName !== 'dev') {
      this.datadogService.init({
        applicationId: environment.datadog.viewerApplicationId,
        clientToken: environment.datadog.viewerClientToken,
        service: environment.datadog.viewerServiceName,
      });
      this.datadogService.initLogs({
        clientToken: environment.datadog.viewerClientToken,
        service: environment.datadog.viewerServiceName,
      });
    }

    if (this.browser.isNativeAppCookieSet()) {
      this.viewerCoreFacade.handleNativePostMessages();
    }
    if (this.browser.isAndroid) {
      this.viewerCoreFacade.handleAndroidLayoutMessages();
    }
  }
}
