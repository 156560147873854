import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ACTIMO_COLORS, SocialGroup } from '@ao/data-models';
import { getImageSourceMinSize } from '@ao/utilities';

@Component({
  selector: 'ao-social-group-item, [ao-social-group-item]',
  templateUrl: './social-group-item.component.html',
  styleUrls: ['./social-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SocialGroupItemComponent {
  @Input() group: SocialGroup;
  @Input() groupName: string;
  @Input() hideCount = false;

  groupImage(group: SocialGroup) {
    const colors: string[] = [
      ACTIMO_COLORS.blue.base,
      ACTIMO_COLORS.green.base,
      ACTIMO_COLORS.yellow.base,
      ACTIMO_COLORS.red.base,
    ];
    if (group.backgroundImages?.length > 0) {
      return `url("${getImageSourceMinSize(group.backgroundImages, 300)}")`;
    }

    if (group.type === 'GROUP') {
      const color = colors[<number>(<unknown>group.id) % colors.length];
      return `linear-gradient(to top, ${color}, ${color})`;
    }

    return undefined;
  }
}
