import { Component, Input } from '@angular/core';

@Component({
  selector: 'ao-viewer-backdrop',
  templateUrl: './viewer-backdrop.component.html',
  styleUrls: ['./viewer-backdrop.component.scss'],
  standalone: false,
})
export class ViewerBackdropComponent {
  @Input() backgroundColor = '#000';
}
