import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContextMenuComponent } from './context-menu.component';

@Directive({
  selector: '[aoContextMenu]',
  exportAs: 'contextMenu',
  standalone: false,
})
export class ContextMenuDirective implements OnDestroy {
  @HostBinding('attr.aria-haspopup') ariaHasPopup = true;
  @HostBinding('attr.aria-expanded') get visible() {
    return this._overlayRef && this._overlayRef.hasAttached();
  }

  @Input('aoContextMenu') contextMenu: ContextMenuComponent;

  private _overlayRef: OverlayRef;
  private portal: TemplatePortal<any>;
  private close$ = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @HostListener('click', ['$event'])
  onHostClick() {
    this.openMenu();
  }

  ngOnDestroy() {
    this.close$.next();
    this.close$.complete();
    this.closeMenu();
  }

  closeMenu() {
    this.document.body.classList.remove('ao-context-menu--open');
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
  }

  private openMenu() {
    this.portal = new TemplatePortal(this.contextMenu._templateRef, this.viewContainerRef);
    const config = new OverlayConfig();
    if (this.contextMenu.mode === 'DESKTOP') {
      config.positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' },
          { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' },
        ]);
      config.backdropClass = 'cdk-overlay-transparent-backdrop';
      config.scrollStrategy = this.overlay.scrollStrategies.reposition();
    } else {
      config.width = '432px';
      config.positionStrategy = this.overlay.position().global().bottom().centerHorizontally();
      config.scrollStrategy = this.overlay.scrollStrategies.block();
    }
    config.scrollStrategy.enable();
    config.hasBackdrop = true;

    this._overlayRef = this.overlay.create(config);

    this.document.body.classList.add('ao-context-menu--open');
    merge(this._overlayRef.backdropClick(), this.contextMenu.actionClick, this.contextMenu.dismiss)
      .pipe(takeUntil(this.close$))
      .subscribe(() => {
        this.closeMenu();
      });
    this._overlayRef.attach(this.portal);
  }
}
