import { ChangeDetectionStrategy, Component, HostBinding, Input, Optional } from '@angular/core';
import { brightness, color as c, highlightColor, shadeColor } from '@ao/utilities';
import { ThemeHandlerService } from '../../services/theme-handler/theme-handler.service';
import { AppButtonColor, IconSize } from '@ao/data-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ao-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ButtonComponent {
  @HostBinding('class.ao-button') className = true;

  @HostBinding('class.ao-button--active')
  @Input()
  active = false;

  @HostBinding('class.ao-button--icon')
  @Input()
  icon: string;

  @HostBinding('class.ao-button--full-width')
  @Input()
  fullWidth = true;

  @HostBinding('class.ao-button--outline')
  @Input()
  outline = false;

  @HostBinding('class.ao-button--rounded')
  @Input()
  rounded = false;

  @Input() buttonSize?: 'normal' | 'medium' | 'compact' = 'normal';

  @Input() iconSize?: IconSize = 24;

  // size variations
  @HostBinding('class.ao-button--size-medium') get sizeMedium() {
    return this.buttonSize === 'medium';
  }
  @HostBinding('class.ao-button--size-compact') get sizeCompact() {
    return this.buttonSize === 'compact';
  }

  @Input() buttonColor?: AppButtonColor = null;

  // only sane way of using dynamic classes and normal classes mixed up on the host:
  // if no color input, default to theme
  @HostBinding('class.ao-button--themed') get getThemeColor() {
    return !this.buttonColor;
  }
  // branding colors
  @HostBinding('class.ao-button--red') get colorRed() {
    return this.buttonColor === 'red';
  }
  @HostBinding('class.ao-button--green') get colorGreen() {
    return this.buttonColor === 'green';
  }
  @HostBinding('class.ao-button--yellow') get colorYellow() {
    return this.buttonColor === 'yellow';
  }
  @HostBinding('class.ao-button--blue') get colorBlue() {
    return this.buttonColor === 'blue';
  }
  @HostBinding('class.ao-button--ink') get colorInk() {
    return this.buttonColor === 'ink';
  }
  @HostBinding('class.ao-button--snow') get colorSnow() {
    return this.buttonColor === 'snow';
  }
  @HostBinding('class.ao-button--transparent') get colorTransparent() {
    return this.buttonColor === 'transparent';
  }

  constructor(@Optional() themeHandler: ThemeHandlerService) {
    if (themeHandler) {
      themeHandler.registerStyle('ButtonComponent', (theme) => {
        const opacity = theme.module_btn_opacity / 100;

        const disabledColor = highlightColor(theme.module_btn_txt_color, (opacity || 1) * 0.5);
        const disabledBgColor = highlightColor(theme.module_btn_bg_color, opacity * 0.7, 1);

        const disabledBorderColor = highlightColor(
          c(theme.module_btn_border_color, '#000000'),
          (opacity || 1) * 0.7,
          1,
        );
        // if button color is too dark, make active lighter, otherwise make active darker
        const shade = brightness(theme.module_btn_bg_color) > 0.4 ? -0.3 : 0.3;

        const activeBgColor = shadeColor(theme.module_btn_bg_color, shade);

        const disabledActiveBgColor = highlightColor(activeBgColor, opacity * 0.5, 1);

        const borderWidth = theme.module_btn_border_width ? `${theme.module_btn_border_width}px` : 'none';
        const borderRadius = theme.module_btn_border_radius;

        return `
        .ao-button {
          border-radius: ${borderRadius}px;
          border-width: 0;
        }
        a.ao-button {
          text-decoration: none;
          text-align: center;
        }

        .ao-button--themed:not(.ao-button--outline) {
          background-color: ${highlightColor(c(theme.module_btn_bg_color), opacity)};
          color: ${c(theme.module_btn_txt_color)};
          border-color: ${c(theme.module_btn_border_color, '#000000')};
          border-width: ${borderWidth};
        }
        a.ao-button.ao-button--themed:not(.ao-button--outline)  {
          color: ${c(theme.module_btn_txt_color)};
        }

        .ao-button.ao-button--themed:not(.ao-button--outline):hover, .ao-button.ao-button--themed:not(.ao-button--outline):focus {
          background-color: ${c(highlightColor(theme.module_btn_bg_color, opacity, 1.15))};
        }
        .ao-button.ao-button--themed:not(.ao-button--outline)[disabled], .ao-button.ao-button--themed[disabled]:hover, .ao-button.ao-button--themed[disabled] {
          color: ${disabledColor};
          background-color: ${disabledBgColor};
          border-color: ${disabledBorderColor};
        }
        .ao-button.ao-button--themed:not(.ao-button--outline).ao-button--active {
          background-color: ${activeBgColor};
        }
        .ao-button.ao-button--themed:not(.ao-button--outline).ao-button--active[disabled] {
          background-color: ${disabledActiveBgColor};
        }


        .ao-button--themed.ao-button--outline {
          background-color: transparent;
          color: ${highlightColor(c(theme.module_btn_bg_color), opacity)};
          border-color: ${highlightColor(c(theme.module_btn_bg_color), opacity)};
          border-width: 1px;
        }
        a.ao-button.ao-button--themed.ao-button--outline  {
          color: ${highlightColor(c(theme.module_btn_bg_color), opacity)};
        }

        .ao-button.ao-button--themed.ao-button--outline:hover, .ao-button.ao-button--themed.ao-button--outline:focus {
          background-color: ${highlightColor(c(theme.module_btn_bg_color), opacity)};
          color: ${c(theme.module_btn_txt_color)};
        }
        .ao-button.ao-button--themed.ao-button--outline[disabled], .ao-button.ao-button--themed.ao-button--outline[disabled]:hover, .ao-button.ao-button--themed.ao-button--outline[disabled] {
          color: ${disabledBgColor};
          border-color: ${disabledBgColor};
        }
        .ao-button.ao-button--themed.ao-button--outline.ao-button--active {
           background-color: ${activeBgColor};
          color: ${c(theme.module_btn_txt_color)};
          border-color: ${activeBgColor};
        }
        .ao-button.ao-button--themed.ao-button--outline.ao-button--active[disabled] {
          color: ${disabledActiveBgColor};
          border-color: ${disabledActiveBgColor};
        }`;
      });
    }
  }
}
