import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AvatarSize, ContextMenuAction, ContextMenuMode } from '@ao/data-models';
import {
  ImageHandlerMedia,
  MessagePublishedNotificationDetails,
  MessageType,
  Notification,
  NotificationDetails,
  NotificationSettingsGroup,
  NotificationType,
  PostNotificationDetails,
  TaskNotificationDetails,
} from '@ao/shared-data-models';
import { BrowserService, encodeHTMLCharacters, fullName, instanceOfPostNotificationDetails } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { actionTypes as notificationListActionTypes } from '../../common/constants';

const enableNotificationsLabel = i18n('Turn on notifications from #channelName#');
const disableNotificationsLabel = i18n('Turn off notifications from #channelName#');

// TODO: add turn off notifications action when AO-915 is ready
const possibleContextMenuActions: Record<string, ContextMenuAction> = {
  markAsRead: { type: notificationListActionTypes.markAsRead, label: i18n('Mark as read') },
  markAsUnread: { type: notificationListActionTypes.markAsUnread, label: i18n('Mark as unread') },
  enableNotifications: { type: notificationListActionTypes.enableNotifications, label: i18n('Turn on notifications') },
  disableNotifications: {
    type: notificationListActionTypes.disableNotifications,
    label: i18n('Turn off notifications'),
  },
};

@Component({
  selector: 'ao-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NotificationListItemComponent implements OnInit {
  @HostBinding('class.ao-notification-list-item') className = true;

  @Input() set groupsUserSettings(value: NotificationSettingsGroup) {
    if (this.notification && value) {
      let notificationsToggle = null;
      const readToggle = this.notification.read
        ? possibleContextMenuActions.markAsUnread
        : possibleContextMenuActions.markAsRead;
      // group notification specific

      if (
        instanceOfPostNotificationDetails(this.notification?.data) &&
        this.translatedLabels &&
        value.disabledGroups &&
        this.notification.type !== NotificationType.MessagePostCommentCreated &&
        this.notification.type !== NotificationType.MessagePostMentionCreated &&
        this.notification.type !== NotificationType.MessagePostReplyCreated
      ) {
        notificationsToggle = [
          value.disabledGroups.includes(this.notification.data.group.id.toString())
            ? {
                ...possibleContextMenuActions.enableNotifications,
                label: this.translatedLabels[enableNotificationsLabel],
              }
            : {
                ...possibleContextMenuActions.disableNotifications,
                label: this.translatedLabels[disableNotificationsLabel],
              },
        ];
      }
      this.contextMenuActions = [[...(notificationsToggle || []), readToggle]];
    }
  }
  @Input() notification: Notification;
  @Output() menuActionSelected = new EventEmitter<string>();
  @Output() notificationClicked = new EventEmitter();

  contextMenuActions: ContextMenuAction[][];

  constructor(
    private browser: BrowserService,
    private translate: TranslateService,
  ) {}

  avatarSize: AvatarSize = 'medium';
  notificationType = NotificationType;
  messageType = MessageType;
  translatedLabels = null;

  get authorFullName() {
    const data = this.notification.data as PostNotificationDetails;
    return data.author
      ? encodeHTMLCharacters(fullName(data.author.firstName, data.author.lastName))
      : this.translatedLabels?.['Inactive user'] || '';
  }

  get messageCategoryName() {
    const data = this.notification.data as MessagePublishedNotificationDetails;

    return data.message.categories?.length ? data.message.categories[0].name : '';
  }

  get rootPostAuthor() {
    const data = this.notification.data as PostNotificationDetails;

    if (!data.rootPostAuthor) {
      return null;
    }

    if (this.notification.contactId === data.rootPostAuthor?.id) {
      return null;
    } else {
      return encodeHTMLCharacters(fullName(data.rootPostAuthor.firstName, data.rootPostAuthor.lastName));
    }
  }

  get defaultIcon() {
    const data = this.notification.data as MessagePublishedNotificationDetails;
    switch (data.message?.type) {
      case 'academy':
        return {
          name: 'school',
          color: 'green',
        };
      case 'pulse':
      case 'survey':
        return {
          name: 'insert_emoticon',
          color: 'reverseBlue',
        };
      case 'rating':
        return {
          name: 'compass_calibration',
          color: 'reverseBlue',
        };
      default:
        return {
          name: 'chat_bubble',
          color: 'yellow',
        };
    }
  }

  get initials(): string {
    const data = this.notification.data as PostNotificationDetails;
    return (data.author?.firstName || '').charAt(0) + (data.author?.lastName || '').charAt(0);
  }

  get contextMenuMode(): ContextMenuMode {
    return this.browser.isMobileOS() ? 'MOBILE' : 'DESKTOP';
  }

  get messageOrAcademyData() {
    return (
      (this.notification.data as MessagePublishedNotificationDetails).academy ||
      (this.notification.data as MessagePublishedNotificationDetails).message
    );
  }

  get messageTitle(): string {
    const data = this.messageOrAcademyData;
    return data.titlePreview || data.title;
  }

  get messageMedia(): ImageHandlerMedia {
    const data = this.messageOrAcademyData;
    return data.mediaPreview || data.media;
  }

  ngOnInit() {
    if (instanceOfPostNotificationDetails(this.notification.data)) {
      this.translate
        .get([enableNotificationsLabel, disableNotificationsLabel, i18n('Inactive user')], {
          channelName: this.notification.data.group.name,
        })
        .subscribe((translations) => {
          this.translatedLabels = translations;
        });
    }
  }

  onClickMenuButton(event: Event) {
    event.stopPropagation();
  }

  onSelectMenuAction(event: string) {
    this.menuActionSelected.emit(event);
  }

  getNotificationPostData(notificationData: NotificationDetails) {
    return notificationData as PostNotificationDetails;
  }
  getNotificationPostGroupName(notificationData: NotificationDetails) {
    return encodeHTMLCharacters((notificationData as PostNotificationDetails)?.group?.name);
  }
  getNotificationTaskDataHeadline(notificationData: NotificationDetails) {
    return encodeHTMLCharacters((notificationData as TaskNotificationDetails)?.taskHeadline);
  }
}
