import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { GenericMessageToast } from '@ao/data-models';

@Component({
  selector: 'ao-viewer-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class ViewerToastComponent {
  // Controls for spacing & display of how long it should be shown
  @Input() hasHeader?: boolean = false;
  @Input() hasFixedSidebar?: boolean = false;
  @Input() displayDuration?: number = 15;
  @Input() closeButton?: boolean = true;

  @Input() toast: GenericMessageToast;
  @Output() toastActionClick = new EventEmitter<void>();
  @Output() dismissed = new EventEmitter<GenericMessageToast>();
}
