import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ao-toggle-slider',
  templateUrl: './toggle-slider.component.html',
  styleUrls: ['./toggle-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ToggleSliderComponent), multi: true }],
  standalone: false,
})
export class ToggleSliderComponent implements ControlValueAccessor {
  private onChange: any;
  private onTouch: any;

  @Input() set checked(value: boolean | undefined) {
    if (value !== undefined) {
      this._checked = value;
    }
  }
  @Input() disabled: boolean;
  @Input() smallSize?: boolean;
  @Input() trueLabel?: string = '';
  @Input() falseLabel?: string = '';
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  _checked: boolean;
  // Generate a random input id, to not have duplicates
  _toggleId: string = 'input-' + Math.floor(Math.random() * 100000);

  @HostListener('click')
  onToggle() {
    if (this.disabled) {
      return;
    }
    this._checked = !this._checked;
    this.change.emit(this._checked);
    if (this.onChange) {
      this.onChange(this._checked);
    }
    if (this.onTouch) {
      this.onTouch();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: any): void {
    this._checked = Boolean(value);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
