import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ao-close-modal-button',
  templateUrl: './close-modal-button.component.html',
  styleUrls: ['./close-modal-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CloseModalButtonComponent {
  @Output() closeModal = new EventEmitter();
}
