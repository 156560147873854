import { animate, animation, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, Input } from '@angular/core';
import { ACTIMO_COLORS, ThumbnailSize } from '@ao/data-models';
import { ImageHandlerMedia } from '@ao/shared-data-models';
import { fixProtocol, getThumbnailUrlv2, WINDOW } from '@ao/utilities';

const SIZES = {
  tiny: 24,
  small: 32,
  medium: 40,
  big: 48,
  bigger: 56,
};

@Component({
  selector: 'ao-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('loadAnimation', [
      transition(':enter', [animation([style({ opacity: 0 }), animate('200ms ease-in', style({ opacity: 1 }))])]),
    ]),
  ],
  standalone: false,
})
export class ThumbnailComponent {
  @HostBinding('class.ao-thumbnail') className = true;
  @HostBinding('attr.size') @Input() size: ThumbnailSize = 'big';
  @HostBinding('style.border-radius.px') @Input() borderRadius = 4;
  @HostBinding('style.background-color') backgroundColor: string = ACTIMO_COLORS.snow.dark;

  @Input() set media(value: ImageHandlerMedia) {
    if (!value) {
      this._loaded = false;
      return;
    }

    // precache image, so we programtically can fade
    // in the image when it is availble.
    // The actual image will be set using css `background-image`
    this._loaded = false;
    const url = fixProtocol(getThumbnailUrlv2(value, SIZES[this.size] * this.window.devicePixelRatio));

    const newImg = new Image();
    newImg.onload = () => {
      this._height = newImg.height;
      this._width = newImg.width;
      this._imageUrl = url;
      this._loaded = true;
      this.ref.markForCheck();
    };
    newImg.src = url;
  }

  iconColor: string = ACTIMO_COLORS.ink.lightest;

  constructor(
    private ref: ChangeDetectorRef,
    @Inject(WINDOW) private window: Window,
  ) {}

  get imageUrl() {
    return `url(${this._imageUrl})`;
  }

  _loaded = false;
  _imageUrl: string;
  _width: number;
  _height: number;
}
