import { Pipe, PipeTransform } from '@angular/core';
import { fixProtocol } from '../../utils/fix-protocol';

// all assets provided by the actimo platform should be through the fixProtocol pipe to ensure https

@Pipe({
  name: 'fixProtocol',
  standalone: false,
})
export class FixProtocolPipe implements PipeTransform {
  transform(url: string): string {
    return fixProtocol(url);
  }
}
