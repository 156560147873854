import { Component, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ao-actimo-input]',
  templateUrl: './actimo-input.component.html',
  styleUrls: ['./actimo-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ActimoInputComponent {
  @HostBinding('class.ao-actimo-input') className = true;
  @HostBinding('class.ao-actimo-input--error')
  @Input()
  hasError = false;
}
