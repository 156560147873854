import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ao-input]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InputComponent {
  @HostBinding('class.ao-input') className = true;
  @HostBinding('class.ao-input--error')
  @Input()
  hasError = false;

  @HostBinding('class.ao-input--medium') get sizeSmall() {
    return this.inputSize === 'small';
  }
  @HostBinding('class.ao-input--medium') get sizeMedium() {
    return this.inputSize === 'medium';
  }
  @HostBinding('class.ao-input--large') get sizeLarge() {
    return this.inputSize === 'large';
  }

  @Input() inputSize: 'small' | 'medium' | 'large' = 'small';
}
