import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultipartUploadService } from '@ao/common-ui';
import { MsgModuleUpload, UploadPartProgressEvent } from '@ao/data-models';
import { environment } from '@ao/environments';
import { MediaItem } from '@ao/shared-data-models';
import * as loadImage from 'blueimp-load-image';
import { Observable, Subject, from, last, map, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuleUploadService {
  private readonly BASE_URL = `${environment.apiBaseUrl}/api/v1/viewer`;

  constructor(
    private http: HttpClient,
    private multipartUploadService: MultipartUploadService,
  ) {}

  uploadFile(module: any, keycode: string, file: File, progress: Subject<UploadPartProgressEvent>) {
    const formData = new FormData();
    formData.append(file.name, file, file.name);
    const url = `${environment.apiBaseUrl}/api/v1/messages/${keycode}/upload/${module.id}`;
    const request = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });
    return this.http.request(request).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          progress.next({ loaded: event.loaded, total: event.total, percent: (event.loaded / event.total) * 100 });
        }
      }),
      last(),
      tap(() => progress.complete()),
      map((res) => (<HttpResponse<any>>res).body.data[0]),
    );
  }

  uploadModuleMediaByConfig({
    file,
    keycode,
    module,
    progressSubject,
    isS3DirectUploadEnabled,
  }: {
    file: File;
    keycode: string;
    module: MsgModuleUpload;
    progressSubject: Subject<UploadPartProgressEvent>;
    isS3DirectUploadEnabled: boolean;
  }): Observable<MediaItem> {
    if (!isS3DirectUploadEnabled) {
      return this.uploadFile(module, keycode, file, progressSubject);
    }

    return (
      file.type?.startsWith('image')
        ? from(loadImage(file, {})).pipe(map((res) => ({ width: res.originalWidth, height: res.originalHeight })))
        : of({ width: null, height: null })
    ).pipe(
      switchMap(({ width, height }) => {
        const startUrl = `${this.BASE_URL}/${keycode}/media/upload/start`;
        const completeUrl = `${this.BASE_URL}/${keycode}/media/upload/complete`;

        return this.multipartUploadService
          .multipartUpload({
            file,
            width,
            height,
            startUrl,
            completeUrl,
            dontSaveFolderItem: true,
            folderPath: module.upload_folder_path,
            progressSubject,
          })
          .pipe(
            switchMap((multipartResponse) =>
              this.http
                .put<{ data: MediaItem }>(
                  `${environment.apiBaseUrl}/api/v1/messages/${keycode}/upload/${module.id}/media/${multipartResponse.id}/save`,
                  {},
                )
                .pipe(map((res) => res.data)),
            ),
          );
      }),
    );
  }

  getMedia(keycode: string, module: MsgModuleUpload, mediaId: number) {
    const url = `${environment.apiBaseUrl}/api/v1/messages/${keycode}/upload/${module.id}/media/${mediaId}`;
    return this.http.get<{ data: MediaItem[] }>(url, { withCredentials: true }).pipe(map((res) => res.data));
  }

  removeFileUpload(_module: MsgModuleUpload, keycode: string, fileId: number) {
    return this.http.request('DELETE', `${environment.apiBaseUrl}/api/v1/messages/${keycode}/upload/${fileId}`);
  }

  changeFileTitle(_module: MsgModuleUpload, keycode: string, fileId: number, title: string) {
    return this.http.put(`${environment.apiBaseUrl}/api/v1/messages/${keycode}/upload/${fileId}`, { title });
  }
}
