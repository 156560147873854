import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ContextMenuAction, ContextMenuMode } from '@ao/data-models';

@Component({
  selector: 'ao-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ContextMenuComponent {
  @Input() mode: ContextMenuMode = 'DESKTOP';
  @Input() actions: ContextMenuAction[][];
  @Input() hasExtraContent = false;
  @Output() dismiss = new EventEmitter();
  @Output() actionClick = new EventEmitter<string>();

  @ViewChild('templateRef', { static: true }) _templateRef: TemplateRef<any>;

  isActionList(item: ContextMenuAction[]) {
    return item.length > 1;
  }

  onDismissClick() {
    this.dismiss.emit();
  }

  onActionClick(action: ContextMenuAction) {
    if (!action.disabled) {
      this.actionClick.emit(action.type);
    }
  }
}
