import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ao-tab]',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TabComponent {
  @HostBinding('attr.role') role = 'tab';
  @HostBinding('class.ao-tab') className = true;

  @HostBinding('class.ao-tab--active')
  @Input()
  active = false;

  @HostBinding('class.ao-tab--styled') _enableStyling = true;

  @HostBinding('class.ao-tab--admin-ui')
  @Input()
  adminUi?: boolean = false;

  // todo - rename this to be more generic, as it is not specific to the chat feature but to new design.
  @HostBinding('class.ao-tab--chat-ui')
  @Input()
  viewerUi?: boolean = false;

  @HostBinding('class.ao-tab--extra-height')
  @Input()
  extraHeight?: boolean = false;

  @Input() set disableStyling(value) {
    this._enableStyling = !value;
  }

  get nativeElement() {
    return this.element.nativeElement;
  }

  constructor(private element: ElementRef) {}
}
