import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';

import { KAHOOT_COLORS, MessageListMessage, QuizStatus } from '@ao/data-models';
import { ImageHandlerMedia } from '@ao/shared-data-models';
import { alphaColor as alpha, color as c, fixProtocol, getThumbnailUrlv2 } from '@ao/utilities';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { ThemeHandlerService } from '../../services/theme-handler/theme-handler.service';

dayjs.extend(duration);

@Component({
  selector: 'ao-messagelist-item',
  templateUrl: './messagelist-item.component.html',
  styleUrls: ['./messagelist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MessagelistItemComponent implements OnInit {
  @Input() item: MessageListMessage | Partial<MessageListMessage>;
  @Input() allowQuizRetake = false;
  @Input() showCategories = true;
  @Input() squaredThumbnails = true;
  @Input() useTheme = false;
  @Input() allowInsecure = false;
  @Input() cardMode = false;
  @Input() kahootMode = false;
  @Input() previewMode = false;
  @Input() showThumbnailPlaceholder = false;
  @Input() thumbnailPlaceholderIcon = 'custom-icons/thumbnail-icon';
  @Input() thumbnailPlaceholderColor = 'reverseInk';
  @Input() title: string;
  @Input() description: string;
  @Input() media: ImageHandlerMedia | null;
  @Input() hasBoxShadow = true;
  @Input() thumbnailUrl?: string; // to be used as a fallback when media is not available
  @Input() clickable = true;
  @Input() categoryLimit = 1;
  @Input() themedContent = true;

  @Output() clicked = new EventEmitter<{ source: string }>();

  private themeHandler: ThemeHandlerService;
  _math: Math = Math;

  constructor(@Optional() themeHandler: ThemeHandlerService) {
    if (themeHandler) {
      this.themeHandler = themeHandler;
      // the ${theme.module_bg_color ? ... } validation is to revert the label color if module_bg_color is set to transparent
      themeHandler.registerStyle(
        'MessagelistItemComponent',
        (theme) => /* css*/ `
        .ao-messagelist-item__headline,
        .ao-messagelist-item__summary {
          color: ${c(theme.module_txt_color)};
        }
        .ao-messagelist-item__meta {
          color: ${alpha(theme.module_txt_color, 30)};
        }
        .ao-messagelist-item__academy-stats__text {
          color: ${alpha(theme.module_txt_color, 30)};
        }
        .ao-messagelist-item__academy-stats__button {
          border.radius: ${theme.module_btn_border_radius};
          }
        .academy-info-wrapper__button{
            border.radius: ${theme.module_btn_border_radius};
        }
      `,
      );
    }
  }
  ngOnInit() {
    if (!this.themedContent) {
      // some pages always have a white background and the themed content is not visible
      this.applyNonThemablePagesContentStyles();
    }
  }

  private applyNonThemablePagesContentStyles(): void {
    this.themeHandler.removeStyle('MessagelistItemComponent');
  }

  get themedColor() {
    return `#${this.themeHandler.theme.module_btn_bg_color}`;
  }

  get duration() {
    if (this.media && this.media.duration) {
      const duration = dayjs.duration(this.media.duration);
      const hours = duration.hours();
      const seconds = duration.seconds().toString().padStart(2, '0');
      const minSecs = `${duration.minutes()}:${seconds}`;

      return hours ? `${hours}:${minSecs.padStart(5, '0:0')}` : minSecs;
    }
    return undefined;
  }

  get showRetakeQuizLink() {
    const quiz = this.item.quiz;
    return this.allowQuizRetake && quiz && quiz.status === QuizStatus.RETAKE_OPEN;
  }

  get showCallout() {
    // for now, this is limited to quiz related callouts
    const quiz = this.item.quiz;
    const ACCEPTED_STATUS = Object.values(QuizStatus).filter((v) => v !== QuizStatus.PRISTINE) as QuizStatus[];
    return this.item.type !== 'academy' && quiz && ACCEPTED_STATUS.includes(quiz.status);
  }

  get showMetaData(): boolean {
    return !!this.item.displayDate || !!this.item.socialStats;
  }

  get hasFooter() {
    return this.showCallout || this.showMetaData || this.item.type === 'academy';
  }

  get iconBackgroundColor() {
    return this.kahootMode ? KAHOOT_COLORS.purple.base : '';
  }

  get showCompletedCalloutMessage() {
    const quiz = this.item.quiz;
    const ACCEPTED_STATUS = [QuizStatus.RETAKE_LOCKED, QuizStatus.RETAKE_OPEN, QuizStatus.COMPLETED];
    return ACCEPTED_STATUS.includes(quiz.status);
  }

  // Returns the number of days until the expiration date
  // Returns a postive number if not expired yet, negative if it is
  get academyExpirationDays() {
    const expirationDate = this.item.academyData && this.item.academyData.expirationDate;
    if (!expirationDate) {
      return 9999;
    }

    return dayjs(expirationDate).diff(dayjs(Date.now()), 'day');
  }

  get hasImage(): boolean {
    return this.media.imageVersions?.length > 0 || !!this.thumbnailUrl;
  }

  get imageThumbnailUrl(): string | undefined {
    const imageUrl = getThumbnailUrlv2(this.media, 150) || this.thumbnailUrl;
    return fixProtocol(imageUrl);
  }
}
