import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'time[aoTime]',
  templateUrl: './time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimeComponent {
  _time: dayjs.Dayjs;
  @HostBinding('attr.title') _timelll: string;
  @HostBinding('attr.datetime') _timeIso: string;
  @Input() set aoTime(value: dayjs.ConfigType) {
    this._time = dayjs(value);
    this._timeIso = this._time.toISOString();
    this._timelll = this._time.format('lll');
  }
  @Input() format: string;
}
