import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as humanizeDuration from 'humanize-duration';
import { combineLatest, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'countdown',
  pure: false,
  standalone: false,
})
export class CountdownPipe implements PipeTransform, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    @Optional() private translate: TranslateService,
    private cdf: ChangeDetectorRef,
  ) {
    if (translate) {
      combineLatest([translate.onLangChange, interval(60 * 1000)])
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.cdf.markForCheck();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  transform(input: dayjs.ConfigType): string {
    let language = this.translate && this.translate.currentLang ? this.translate.currentLang.split('-')[0] : 'en';
    // Convert language for Norwegian
    // See: https://www.npmjs.com/package/humanize-duration#supported-languages
    if (language === 'nb') {
      language = 'no';
    }
    const ds = dayjs(input).diff(Date.now(), 'ms');

    const units = ds > 60000 ? ['d', 'h', 'm'] : ['d', 'h', 'm', 's'];
    return humanizeDuration(ds, {
      language,
      round: true,
      units: units,
    });
  }
}
