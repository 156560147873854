import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CometchatNotificationSettings, CometChatUser } from '@ao/data-models';
import { Observable, defer, from } from 'rxjs';
import { map, switchAll } from 'rxjs/operators';
import { ChatFacade } from '../chat-store.facade';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private chatFacade = inject(ChatFacade);

  getInitialHasUnreadMessages(): Observable<boolean> {
    return defer(() => import('@cometchat/chat-sdk-javascript')).pipe(
      map(({ CometChat }) => {
        const userConver = new CometChat.ConversationsRequestBuilder().setLimit(30).build();
        return from(userConver.fetchNext()).pipe(
          map(
            (conversations: any[]) =>
              conversations
                .filter((conver) => conver.getUnreadMessageCount() > 0)
                .map((conver) => {
                  CometChat.markAsDelivered(conver.getLastMessage()).then(
                    // eslint-disable-next-line
                    () => {},
                    (error: unknown) => {
                      console.warn('An error occurred when marking the message as delivered.', error);
                    },
                  );
                  return conver;
                }).length > 0,
          ),
        );
      }),
      switchAll(),
    );
  }

  getLoggedInUser(): Observable<CometChatUser> {
    return from(
      import('@cometchat/chat-sdk-javascript').then(({ CometChat }) => {
        return CometChat.getLoggedinUser() as unknown as Promise<CometChatUser>;
      }),
    );
  }

  fetchConversationAdmins(groupID: string) {
    const scopes: string[] = ['admin', 'owner'];

    return from(
      import('@cometchat/chat-sdk-javascript').then(({ CometChat }) => {
        const conversationAdmins: CometChat.GroupMembersRequest = new CometChat.GroupMembersRequestBuilder(groupID)
          .setLimit(0)
          .setScopes(scopes)
          .build();
        return conversationAdmins.fetchNext();
      }),
    );
  }

  getCometchatPushNotificationStatus(): Observable<CometchatNotificationSettings> {
    return from(
      (async () => {
        const { CometChat } = await import('@cometchat/chat-sdk-javascript');

        const notificationSettingsResponse: any = await CometChat.callExtension(
          'push-notification',
          'GET',
          'v1/user-settings',
          {},
        );
        return notificationSettingsResponse['user-settings'] as CometchatNotificationSettings;
      })(),
    );
  }
  currentNotificationSettings = toSignal(this.chatFacade.notificationSettings$);
  toggleCometchatNotifications() {
    return from(
      (async () => {
        const { CometChat } = await import('@cometchat/chat-sdk-javascript');

        const userSettings = {
          'user-settings': {
            ...this.currentNotificationSettings(),
            dnd: !this.currentNotificationSettings()?.dnd,
          },
        };

        const notificationSettingsResponse = (await CometChat.callExtension(
          'push-notification',
          'POST',
          'v1/user-settings',
          userSettings,
        )) as CometchatNotificationSettings;

        return notificationSettingsResponse;
      })(),
    );
  }
}
