import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GenericMessageToast } from '@ao/data-models';
import { Action, Store } from '@ngrx/store';
import { ViewerCoreFacade } from '../../store/viewer-core-store.facade';

@Component({
  selector: 'ao-viewer-toast-display',
  templateUrl: './toast-display.component.html',
  styleUrls: ['./toast-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ViewerToastDisplayComponent {
  store = inject(Store);
  viewerCoreFacade = inject(ViewerCoreFacade);

  _toast = toSignal(this.viewerCoreFacade.toast$);

  @Input() hasHeader = false;

  get toast() {
    return this._toast();
  }

  dismissGenericToast(_toast: GenericMessageToast) {
    this.viewerCoreFacade.dismissToast();
  }
  genericToastAction(action: Action) {
    if (action) {
      this.store.dispatch(action);
    }
  }
}
