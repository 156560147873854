import { Directive, HostBinding, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { fixProtocol } from '../utils';

@Directive({
  selector: '[aoSrc]',
  standalone: false,
})
export class SourceDirective {
  @Input('aoSrc') source: string | SafeUrl;

  @HostBinding('src') get src() {
    // return empty string for falsy values to avoid
    // `/null` or `/undefined` request being fired - use default empty image icon
    if (!this.source) return '/assets/material-icons/image.svg';
    return fixProtocol(this.source as string);
  }
}
