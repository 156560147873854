import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact, Sidebar, SidebarMenuDataItem } from '@ao/data-models';
import { color as c, highlightColor } from '@ao/utilities';

@Component({
  selector: 'ao-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SidebarContentComponent {
  _sidebarStyle = {};
  _headerStyle = {};

  private _sidebar;

  @Input() menuExpandedDesktop: boolean;
  @Input() unreadCounts: number[][];
  @Input() contact?: Contact;
  @Input() contactInitials?: string;
  @Input() profileLink?: string[];
  @Input() topBarClickTarget?: number;

  @Input() set sidebar(value: Sidebar) {
    this._sidebar = value;
    this._sidebarStyle = {
      'background-color': c(value?.menu_bg_color, '#ffffff'),
      color: c(value?.menu_color, '#000000'),
      '--menu-item-line-color': `${highlightColor(c(value?.menu_color, '#ffffff'), 0.2, 1)}`,
    };
    this._headerStyle['background-color'] = c(value?.branding_bg_color, 'transparent');
  }
  get sidebar() {
    return this._sidebar;
  }

  @Output() menuItemClicked = new EventEmitter<SidebarMenuDataItem>();
  @Output() profileClicked = new EventEmitter<string[]>();
  @Output() topBarClicked = new EventEmitter();
}
