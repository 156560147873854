import { ErrorHandler, Injectable, inject } from '@angular/core';
import { DatadogService } from '@ao/utilities';

function getHTMLBundleVersion(htmlDocumentString: string): string | false {
  const regEx = /main\.([A-Z0-9]\w+)\.js/m;
  const match = htmlDocumentString.match(regEx);
  return match?.length > 1 ? match[1] : false;
}

async function getLatestHTMLBundleVersionFromServer() {
  const current = (window as any).location.href;
  const url = new URL(current);
  url.searchParams.set('cCache', Date.now().toString());
  return fetch(url)
    .then((response) => {
      return response.text();
    })
    .then((responseText) => {
      return getHTMLBundleVersion(responseText);
    });
}

@Injectable()
export class ViewerErrorHandler implements ErrorHandler {
  datadogService = inject(DatadogService);
  bundleVersion: string | false = false;
  chunkErrorsCaught = 0;

  handleError(error: unknown): void {
    // in case the error happened before the app was loaded and revealed to the user.
    // We display a generic error message and ask to reload
    (window as any).appLoadingError();

    // downgrade the svg error to a warning in datadog
    if (error instanceof Error && error.message && error.message.includes('Loading chunk')) {
      console.warn(error);
      if (this.chunkErrorsCaught % 10 === 0) {
        getLatestHTMLBundleVersionFromServer().then((res) => {
          this.bundleVersion = getHTMLBundleVersion(document.body.innerHTML);
          if (res && res !== this.bundleVersion) {
            this.datadogService.log('New bundle version detected - dispatch reload event');
            window.dispatchEvent(new CustomEvent('chunkError updates'));
          }
        });
      }
      this.chunkErrorsCaught++;
      this.datadogService.warn(
        error.message,
        { bundleVersion: this.bundleVersion, chuckErros: this.chunkErrorsCaught },
        error,
      );
      return;
    }

    if (error instanceof Error && error.message && error.message.includes('.svg?')) {
      this.datadogService.warn(error.message);
      return;
    }

    // console.error  will be sent to datadog in production
    console.error(error);
  }
}
