import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from '@angular/core';
import { ErrorStateItemType } from '@ao/data-models';
import { WINDOW } from '@ao/utilities';

@Component({
  selector: 'ao-error-state-item',
  templateUrl: './error-state-item.component.html',
  styleUrls: ['./error-state-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ErrorStateItemComponent {
  @HostBinding('class.ao-error-state-item') readonly className = true;

  @Input() type: ErrorStateItemType;

  readonly ErrorStateItemType = ErrorStateItemType;

  constructor(@Inject(WINDOW) private _window: Window) {}

  reloadPage() {
    this._window.location.reload();
  }
}
