import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';

import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

// dayJs config
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as isYesterday from 'dayjs/plugin/isYesterday';
import * as isToday from 'dayjs/plugin/isToday';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(isToday);

// todo: rename to dayJsFormat
@Pipe({
  name: 'aoDateFormat',
  pure: false,
  standalone: false,
})
export class dateFormat implements PipeTransform, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    @Optional() translate: TranslateService,
    private cdf: ChangeDetectorRef,
  ) {
    if (translate) {
      translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.cdf.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  transform(input: dayjs.ConfigType, format = 'lll', useFromNow = true): string {
    if (format === 'chat') {
      input = Number(input) * 1000; // cometchat data is always a number, dayjs(input) accepts miliseconds
    }

    const d = dayjs(input);
    if (!d.isValid()) {
      return '';
    }

    const isYesterday = d.isYesterday();
    const isToday = d.isToday();
    const lessThanADay = [-1, 0, 1].includes(dayjs(Date.now()).diff(d, 'day'));
    const lessThanAWeek = dayjs(Date.now()).diff(d, 'day') < 7;
    switch (format) {
      case 'social': {
        const currentYear = d.year() === dayjs().year();
        const secondsDiff = dayjs().diff(d, 'seconds');
        if (secondsDiff < 60) {
          return i18n('Just now');
        } else if (lessThanADay) {
          return d.fromNow();
        } else if (currentYear) {
          return d.format('D MMM');
        } else {
          return d.format('ll');
        }
      }
      case 'messagelist': {
        const currentYear = d.year() === dayjs().year();
        if (lessThanAWeek) {
          return d.fromNow();
        } else if (currentYear) {
          return d.format('D MMM');
        } else {
          return d.format('ll');
        }
      }
      case 'chat': {
        const currentYear = d.year() === dayjs().year();
        if (isToday) {
          return i18n('Today');
        } else if (isYesterday) {
          return i18n('Yesterday');
        } else if (lessThanAWeek) {
          return d.format('ddd D MMM');
        } else if (!currentYear) {
          return d.format('D MMM YYYY');
        } else {
          return d.format('ll');
        }
      }
      case 'scheduled': {
        return `${d.format('lll')} (GMT ${d.format('Z')})`;
      }
      case 'messageStats': {
        return d.format('D MMM YYYY');
      }
      default: {
        // if diff more than 1 day, lll format: Mar 22, 2018 9:44 AM, otherwise fromNow (yesterday/tomorrow/hours, etc)
        return useFromNow && lessThanADay ? d.fromNow() : d.format(format);
      }
    }
  }
}
