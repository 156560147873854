import { HttpClient } from '@angular/common/http';
import { Directive, Input } from '@angular/core';
import { environment } from '@ao/environments';

@Directive({
  selector: '[aoUnsplashTrackViews]',
  standalone: false,
})
export class UnsplashTrackViewsDirective {
  @Input('aoUnsplashTrackViews') set unsplashImageId(imageId: string) {
    if (imageId) {
      const url = `${this.BASE_URL}?appId=${environment.unsplash.appId}&photo_id=${imageId}`;
      this.http.get(url).subscribe();
    }
  }

  private readonly BASE_URL = 'https://views.unsplash.com/v';

  constructor(private http: HttpClient) {}
}
