import { Injectable } from '@angular/core';
import { ChatMessageReactions, CometChatUser, UnreadStatus } from '@ao/data-models';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import * as chatActions from './+state/chat-store.actions';
import * as fromChat from './+state/chat-store.reducer';
import * as chatSelectors from './+state/chat-store.selectors';
import { ChatRealtimeListenersService } from './services/chat-realtime-events.service';

@Injectable({ providedIn: 'root' })
export class ChatFacade {
  chatHasUnreadMessages$: Observable<boolean> = this.store.pipe(select(chatSelectors.selectChatHasUnreadMessages));
  notificationSettings$ = this.store.pipe(select(chatSelectors.selectNotificationSettings));
  private cometChat: typeof import('@cometchat/chat-sdk-javascript').CometChat | null = null;
  private LISTENER_ID = 'chat_unread_badge';

  constructor(
    private store: Store<fromChat.ChatState>,
    private chatRealtimeService: ChatRealtimeListenersService,
  ) {}

  // Lazy-load CometChat SDK
  private async loadCometChat(): Promise<typeof import('@cometchat/chat-sdk-javascript').CometChat> {
    if (!this.cometChat) {
      const { CometChat } = await import('@cometchat/chat-sdk-javascript');
      this.cometChat = CometChat;
    }
    return this.cometChat;
  }

  updateChatHasUnread(updateStatus: UnreadStatus) {
    this.store.dispatch(chatActions.UpdateChatHasUnread({ updateStatus }));
  }

  chatOpened() {
    this.store.dispatch(chatActions.ChatOpened());
    this.initRealtimeListeners();
  }

  initRealtimeListeners(): void {
    this.chatRealtimeService.initRealtimeListeners();
  }

  async setupChatMessageListener(): Promise<void> {
    const CometChat = await this.loadCometChat();

    CometChat.addMessageListener(
      this.LISTENER_ID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: unknown) => {
          this.updateChatHasUnread(UnreadStatus.NEW_UNREAD);
          CometChat.markAsDelivered(textMessage).then(
            // eslint-disable-next-line
            () => {},
            (error: unknown) => {
              console.warn('An error occurred when marking the message as delivered.', error);
            },
          );
        },
        onMediaMessageReceived: (mediaMessage: unknown) => {
          this.updateChatHasUnread(UnreadStatus.NEW_UNREAD);
          CometChat.markAsDelivered(mediaMessage).then(
            // eslint-disable-next-line
            () => {},
            (error: unknown) => {
              console.warn('An error occurred when marking the message as delivered.', error);
            },
          );
        },
        onCustomMessageReceived: (customMessage: unknown) => {
          this.updateChatHasUnread(UnreadStatus.NEW_UNREAD);
          CometChat.markAsDelivered(customMessage).then(
            // eslint-disable-next-line
            () => {},
            (error: unknown) => {
              console.warn('An error occurred when marking the message as delivered.', error);
            },
          );
        },
      }),
    );
  }

  async removeChatMessageListener(): Promise<void> {
    const CometChat = await this.loadCometChat();
    CometChat.removeMessageListener(this.LISTENER_ID);
  }

  groupConversationOpened(groupID: string) {
    this.store.dispatch(chatActions.fetchCurrentConversationAdmin({ groupID }));
  }
  userConversationOpened() {
    // for the moment reset currentConversationAdmin. More will be implemented in redux refactor
    this.store.dispatch(chatActions.resetCurrentConversationAdmin());
  }

  chatMessageSent(payload: {
    chatId: string;
    chatMediaUploaded: boolean;
    chatType: 'group' | 'one-to-one';
    chatMediaUploadType?: 'file' | 'video' | 'image' | 'audio';
    chatIsReply: boolean;
  }) {
    this.store.dispatch(chatActions.ChatMessageSent(payload));
  }

  chatReactionOpened(selectedMessageReactions: ChatMessageReactions) {
    this.store.dispatch(chatActions.setSelectedMessageReations({ selectedMessageReactions }));
    this.store.dispatch(chatActions.openMessageReactions());
  }

  chatReactionsClose() {
    this.store.dispatch(chatActions.closeMessageReactions());
    this.store.dispatch(chatActions.resetSelectedMessageReactions());
  }

  // Selectors
  getSelectedMessageReactions$(): Observable<ChatMessageReactions> {
    return this.store.pipe(select(chatSelectors.selectSelectedMessageReactions));
  }
  getChatReactionsOpened$(): Observable<boolean> {
    return this.store.pipe(select(chatSelectors.selectReactionsModalOpened));
  }
  getLoggedInContact$(): Observable<CometChatUser> {
    return this.store.pipe(select(chatSelectors.selectLoggedInContact));
  }
  currentGroupAdmins() {
    return this.store.pipe(select(chatSelectors.selectCurrentGroupAdmins));
  }
  loggedInUserIsAdmin(): Observable<boolean> {
    return this.store.pipe(
      select(chatSelectors.isLoggedinUserAnAdmin),
      map((result) => (Array.isArray(result) ? false : !!result)),
    );
  }
  loggedInUser() {
    return this.store.pipe(select(chatSelectors.selectLoggedInUser));
  }

  toggleNotifications() {
    return this.store.dispatch(chatActions.toggleCometchatNotifications());
  }
}
