import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { color as c } from '@ao/utilities';
import { ThemeHandlerService } from '../../services/theme-handler/theme-handler.service';

@Component({
  selector: 'ao-messagelist-item-skeleton',
  templateUrl: './messagelist-item-skeleton.component.html',
  styleUrls: ['./messagelist-item-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MessagelistItemSkeletonComponent {
  constructor(@Optional() themeHandler: ThemeHandlerService) {
    if (themeHandler) {
      themeHandler.registerStyle(
        'MessagelistItemSkeletonComponent',
        (theme) => /* css*/ `
        .ao-skeleton-background {
          background-color: ${c(theme.module_txt_color)};
        }
      `,
      );
    }
  }
}
