import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState, CHAT_FEATURE_KEY } from './chat-store.reducer';
import { CometChat } from '@cometchat/chat-sdk-javascript';

export const selectChatState = createFeatureSelector<ChatState>(CHAT_FEATURE_KEY);

export const selectChatHasUnreadMessages = createSelector(selectChatState, (state) => state.chatHasUnread);
export const selectLoggedInContact = createSelector(selectChatState, (state) => state.loggedInUser);

export const selectSelectedMessageReactions = createSelector(
  selectChatState,
  (state) => state.selectedMessageReactions,
);
export const selectReactionsModalOpened = createSelector(selectChatState, (state) => state.reactionsModalOpened);

export const selectCurrentGroupAdmins = createSelector(selectChatState, (state) => state.currentConversationAdmins);

export const selectLoggedInUser = createSelector(selectChatState, (state) => state.loggedInUser);

export const isLoggedinUserAnAdmin = createSelector(
  selectCurrentGroupAdmins,
  selectLoggedInUser,
  (admins, loggedInUser) => {
    // Check if the loggedInUser is in the array of admins
    return admins ? admins.some((admin: CometChat.GroupMember) => admin?.getUid() === loggedInUser?.uid) : [];
  },
);
export const selectNotificationSettings = createSelector(selectChatState, (state) => {
  return state.notificationSettings;
});
