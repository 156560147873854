import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Inject, Input, Output } from '@angular/core';
import { BrandingColor } from '@ao/data-models';
import { ImageSource } from '@ao/shared-data-models';
import { WINDOW, fixProtocol, getImageSourceMinSize } from '@ao/utilities';

@Component({
  selector: 'ao-media-attachment',
  templateUrl: './media-attachment.component.html',
  styleUrls: ['./media-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MediaAttachmentComponent {
  @HostBinding('class') get className() {
    const classNames = ['ao-media-attachment', this.small ? 'small' : null];
    return classNames.join(' ');
  }

  private _thumbnailUrl?: string;
  thumbnailSize = 100;

  @Input() small = false;
  @Input() progress: number;
  @Input() spinner?: boolean;
  @Input() overlay?: boolean;
  @Input() images?: ImageSource[];
  @Input() removable = true;
  @Input() videoIcon = false;
  @Input() iconName?: string;
  @Input() iconColor?: BrandingColor;
  @Input() iconDark = false;
  @Input() label?: string;
  @Input() labelSuffix?: string;
  @Input() blackBg? = false;

  @Input() set thumbnailUrl(value) {
    this._thumbnailUrl = value;
  }

  get thumbnailUrl(): string | undefined {
    const size = this.thumbnailSize * this._window.devicePixelRatio;
    const imgUrl = getImageSourceMinSize(this.images, size);
    if (imgUrl) {
      return fixProtocol(imgUrl);
    }
    return this._thumbnailUrl ? fixProtocol(this._thumbnailUrl) : undefined;
  }

  @Output() remove = new EventEmitter();

  constructor(@Inject(WINDOW) private _window: Window) {}

  onRemoveClick() {
    if (this.removable) {
      this.remove.emit();
    }
  }
}
