import { Component, EventEmitter, HostBinding, Inject, Input, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrandingColor, GenericListItemType } from '@ao/data-models';
import { fadeIn, makeHttps, WINDOW } from '@ao/utilities';

@Component({
  selector: 'ao-link-preview-card',
  templateUrl: './link-preview-card.component.html',
  styleUrls: ['./link-preview-card.component.scss'],
  animations: [fadeIn('fadeIn', 400)],
  standalone: false,
})
export class LinkPreviewCardComponent {
  @HostBinding('class.ao-link-preview-card') className = true;

  @HostBinding('class.ao-link-preview-card--warning') get warningType() {
    return this.previewType === 'warning';
  }

  @Input() description: string;
  @Input() imageUrl?: string;
  @Input() previewType?: 'disabled' | 'warning';
  @Input() previewTitle: string;
  @Input() set previewUrl(value) {
    if (value) {
      this._previewUrl = value;
      const url = new URL(value);
      this.hostName = url.hostname.replace('www.', '');
    }
  }
  get previewUrl() {
    return this._previewUrl;
  }
  @Input() previewLayout: 'list-item' | 'card' = 'list-item';
  @Input() loading = false;
  @Input() showCloseButton = false;

  @Output() previewClose = new EventEmitter();

  private _previewUrl: string;
  hostName: string;

  get listItemType(): GenericListItemType {
    if (this.previewType) {
      return 'icon';
    }
    return 'coverImage';
  }

  get iconColor(): BrandingColor {
    if (this.previewType === 'warning') {
      return 'yellow';
    }
    return undefined;
  }

  get iconBackgroundColor(): BrandingColor {
    if (this.previewType === 'warning') {
      return 'yellow';
    } else if (this.previewType === 'disabled') {
      return 'snow';
    }
    return undefined;
  }

  get iconName(): string {
    if (this.previewType === 'warning') {
      return 'warning';
    } else if (this.previewType === 'disabled') {
      return 'image';
    }
    return '';
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private sanitizer: DomSanitizer,
  ) {}

  onClose(event: Event) {
    event.preventDefault();
    this.previewClose.emit();
  }

  openLink() {
    const url = this.sanitizer.sanitize(SecurityContext.URL, this.previewUrl);
    this.window.open(makeHttps(url, false), '_blank', 'noopener, noreferrer');
  }
}
