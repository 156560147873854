import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { getMimeCategory, getMimeIconUrl } from '@ao/utilities';

@Component({
  selector: 'ao-uploaded-file',
  templateUrl: './uploaded-file.component.html',
  styleUrls: ['./uploaded-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UploadedFileComponent {
  @Input() processing: boolean;
  @Input() progress: number;
  @Input() dismissable = false;
  @Input() name: string;
  @Input() allowDescription = false;
  @Input() description?: string = '';
  @Input() type: string;
  @Input() size: number;
  @Input() inComment = false;
  @Input() set audioUrl(value: string) {
    this.audioSources = this.type?.slice(0, 5) === 'audio' && value ? [{ type: this.type, src: value }] : null;
  }

  @HostBinding('class.ao-uploaded-file--in-chat')
  @Input()
  inChat = false;

  audioSources: { type: string; src: string }[] = null;

  get nameStart() {
    return this.name?.substring(0, this.name.length - 6) || '';
  }
  get nameEnd() {
    return this.name?.slice(-6) || '';
  }

  get shortType() {
    const val = getMimeCategory(this.type);
    return this.type ? (val === 'sheet' ? 'xls' : val) : this.type;
  }

  @Output() addDescription = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter();

  get fileIcon() {
    // Temporary fix until cometchat fixes bug AO-2371
    if (this.type === 'application/zip' && this.name.includes('.xls')) {
      this.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    const iconUrl = getMimeIconUrl(getMimeCategory(this.type));
    return iconUrl ? `url('${iconUrl}')` : '';
  }

  onAddDescriptionClick() {
    this.addDescription.emit();
  }
}
