/**
 * @desc Returns the smallest thumbnail with at least 'minSize' or the original thumbnail.
 * @param {object} media
 * @param {int} minSize Minium size for the thumbnail in pixels. Set it to use width.
 * @return {string} The thumbnail's URL
 */

import { ActimoMedia } from '@ao/data-models';
import { ImageHandlerMedia, ImageSource } from '@ao/shared-data-models';

// TODO: delete this function when all subtasks of https://actimo.atlassian.net/browse/AO-2117 are completed
export function getThumbnailUrl(media: Partial<ActimoMedia>, minSize: number) {
  return getImageSourceMinSize(media.images, minSize) || '';
}

export function getThumbnailUrlv2(media: ImageHandlerMedia, minSize: number) {
  return getImageSourceMinSize(media.imageVersions, minSize) || '';
}

export function getImageSourceMinSize(images: ImageSource[] = [], minSize = 100): string | undefined {
  if (images?.length > 1) {
    // find closest size
    const closestSize = images.reduce((closest: number, item: ImageSource) => {
      return minSize <= item.size?.width && item.size?.width < closest ? item.size?.width : closest;
    }, Number.MAX_SAFE_INTEGER);
    const original = images.find((item) => item.size?.original);
    // return url for that width
    return (images.find((e) => e.size?.width === closestSize) || original)?.url;
  }
  return images?.[0]?.url;
}
