import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { ACTIMO_COLORS, BrandingColor } from '@ao/data-models';

@Component({
  selector: 'ao-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgClass],
  standalone: false,
})
export class ProgressBarComponent implements OnChanges {
  _color: string = ACTIMO_COLORS.green.base;
  backgroundColor: string;

  @Input() size = 'small';
  @Input() set color(val: BrandingColor | string) {
    this._color = ACTIMO_COLORS[val]?.base || val;
    this.backgroundColor =
      val === 'blue'
        ? ACTIMO_COLORS.blue.lighter
        : val === 'red'
          ? ACTIMO_COLORS.red.lightest
          : ACTIMO_COLORS.snow.base;
  }

  @Input() value = 0;

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.ngClass.ngClass = {
      'ao-progress-bar': true,
      [`ao-progress-bar--size-${this.size}`]: true,
    };
    this.ngClass.ngDoCheck();
  }
}
