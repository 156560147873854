import { SavedContentFacade } from './saved-content.facade';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSavedContent from './+state/reducers/saved-content.reducer';
import { SavedContentEffects } from './+state/effects/saved-content.effects';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    StoreModule.forFeature(fromSavedContent.SAVED_CONTENT_FEATURE_KEY, fromSavedContent.savedContentReducer),
    EffectsModule.forFeature([SavedContentEffects]),
  ],
  providers: [SavedContentFacade],
})
export class ViewerSavedContentModule {}
