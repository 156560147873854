import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { IntervalFormat } from '@ao/data-models';
import { TranslateService } from '@ngx-translate/core';
// dayJs config
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import '../../utils/dayjs-locale-bundle';
import { capitalize } from '../../utils/string-formatters';

dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

// Shows a readable and locale aware "year interval". Some examples:
// {{ '2018-11-15' | yearInterval: 'weekly' }} -> 'W46'
// {{ '2018-11-15' | yearInterval: 'monthly' }} -> 'November'
// {{ '2018-11-15' | yearInterval: 'quarterly' }} -> 'Q4'
@Pipe({
  name: 'yearInterval',
  pure: false,
  standalone: false,
})
export class YearIntervalPipe implements PipeTransform, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    @Optional() private translate: TranslateService,
    private cdf: ChangeDetectorRef,
  ) {
    if (translate) {
      translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.cdf.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  transform(input: dayjs.ConfigType, interval: IntervalFormat): Observable<string> {
    const d = dayjs(input);
    switch (interval) {
      case 'weekly':
        return this.translate ? this.translate.get('W#week#', { week: d.week() }) : of(`W${d.week()}`);
      case 'monthly':
        // Spanish translations for some reason are lowercase which looks ugly, capitalize ensure consistency
        return of(capitalize(d.format('MMMM')));
      case 'quarterly':
        return this.translate ? this.translate.get('Q#quarter#', { quarter: d.quarter() }) : of(`Q${d.quarter()}`);
    }
  }
}
