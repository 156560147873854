import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output, TrackByFunction } from '@angular/core';

@Component({
  selector: 'ao-audio-waveform',
  templateUrl: './audio-waveform.component.html',
  styleUrls: ['./audio-waveform.component.scss'],
  standalone: false,
})
export class AudioWaveformComponent implements OnDestroy {
  @HostBinding('class.ao-audio-waveform') className = true;

  @Input() set recording(value: boolean) {
    if (value) {
      // repeat with the interval of 2 seconds
      this.recordAnimation = setInterval(() => this.playAnimation(), 150);
    } else {
      clearInterval(this.recordAnimation);
      this.recordAnimation = null;
      this.audioBar = [...this.defaultWave];
    }
  }

  @Input() playHeadPercent = 0;

  @Output() setPlayHeadPercentage = new EventEmitter<number>();

  get playHead() {
    return this.playHeadPercent ? (this.audioBar.length / 100) * this.playHeadPercent : 0;
  }
  private defaultWave = [38, 51, 77, 89, 99, 77, 64, 77, 38, 51, 77, 89, 99, 77, 64, 77, 12, 38, 51, 77];
  audioBar = [...this.defaultWave];
  recordAnimation;

  ngOnDestroy() {
    clearInterval(this.recordAnimation);
  }

  playAnimation() {
    const min = 5;
    const max = 99;

    this.audioBar.forEach((line, index) => {
      this.audioBar[index] = Math.floor(Math.random() * (max - min + 1) + min);
    });
  }

  trackByIndex: TrackByFunction<any> = (index) => index;

  setPlayhead(step) {
    this.setPlayHeadPercentage.emit(step / (this.audioBar.length / 100));
  }
}
