import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SupportSettings } from '@ao/data-models';
import { makeHttps } from '@ao/utilities';

@Component({
  selector: 'ao-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('container', [
      state('void', style({ opacity: 0, transform: 'scale(0.7)' })),
      transition(':enter', animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 }))),
    ]),
  ],
  standalone: false,
})
export class SupportModalComponent {
  @HostBinding('class.ao-support-modal') className = true;

  @Input() supportSettings: SupportSettings = { name: 'Actimo' };

  @Input() loginTheme = false;

  @Input() openSupportLinkInNewTab = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();

  get supportUrl() {
    if (this.supportSettings.url) {
      return this.supportSettings.url?.startsWith('http')
        ? makeHttps(this.supportSettings.url)
        : 'https://' + this.supportSettings.url;
    }
    return null;
  }
}
