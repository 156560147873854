import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ao-pin-code-input',
  templateUrl: './pin-code-input.component.html',
  styleUrls: ['./pin-code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PinCodeInputComponent), multi: true }],
  standalone: false,
})
export class PinCodeInputComponent implements OnInit {
  @HostBinding('class.ao-pin-code-input') className = true;

  @ViewChildren('input') _passcodeInputs: QueryList<ElementRef>;

  @Input() disabled: boolean;

  _length = 4;
  _passcode: string[];

  private _blockKey = false;
  private _previous: string;
  private initialWrite = true;

  ngOnInit(): void {
    this._passcode = new Array(this._length).fill('');
    this.initialWrite = true;
  }

  trackByIndex(index: number) {
    return index;
  }

  focus() {
    let index = this._passcode.findIndex((n) => n === '');
    if (index < 0) {
      index = this._length - 1;
    }
    this.focusInput(index);
  }

  onInputChange() {
    const pincode = this._passcode.join('');
    if (pincode !== this._previous && this.onModelChange) {
      this._previous = pincode;
      setTimeout(() => {
        this.onModelChange(pincode);
      });
    }
  }

  // This is used to prevent holding down keys
  onInputKeyDown(index: number, event: KeyboardEvent) {
    if (this._blockKey) {
      event.preventDefault();
      return;
    } else {
      this._blockKey = true;
      this._passcode[index] = '';
      if (event.key === 'Backspace' && index > 0) {
        this._passcode[index - 1] = '';
        this.focusInput(index - 1);
        this._blockKey = false;
      }
    }
  }

  onInputKeyPress(index: number, event: KeyboardEvent) {
    if (!/[0-9]/.test(event.key) || this._passcode[index].length === 1) {
      event.preventDefault();
    }
  }

  onInputKeyUp(index: number) {
    this._blockKey = false;
    if (index < this._length - 1 && this._passcode[index] !== '') {
      this.focusInput(index + 1);
    }
  }

  private focusInput(index: number) {
    setTimeout(() => this.getInput(index).focus());
  }

  private getInput(index: number): HTMLInputElement {
    return this._passcodeInputs.toArray()[index].nativeElement;
  }

  // CONTROL VALUE ACCESSOR
  /* eslint-disable @typescript-eslint/member-ordering */
  private onTouch: any;
  private onModelChange: any;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  registerOnChange(fn: any) {
    this.onModelChange = fn;
  }
  writeValue(value: string) {
    if (typeof value === 'string') {
      const val = value.slice(0, this._length).split('');
      this._passcode = [...val, ...new Array(this._length - val.length).fill('')];
      // Resetting the focus to initial input box when a reset is done
      if (value === '' && !this.initialWrite) {
        this.focusInput(0);
      } else if (this.initialWrite) {
        // First write we need to wait for the animation to complete
        this.initialWrite = false;
        setTimeout(() => this.focusInput(0), 1000);
      }
    }
  }
}
