import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ThemeHandlerService } from '@ao/common-ui';
import { alphaColor as alpha, color as c } from '@ao/utilities';

@Component({
  selector: 'ao-rating-header',
  templateUrl: './rating-header.component.html',
  styleUrls: ['./rating-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RatingHeaderComponent {
  @Input() title: string;
  @Input() name: string;
  @Input() showEdit = false;
  @Output() historyClick = new EventEmitter();
  @Output() editClick = new EventEmitter();

  constructor(@Optional() themeHandler: ThemeHandlerService) {
    if (themeHandler) {
      themeHandler.registerStyle(
        'MessagelistItemComponent',
        (theme) => /* css*/ `
        .ao-rating-header__button--history {
          border-color: ${c(theme.module_txt_color, '000000')};
          color: ${c(theme.module_txt_color, '000000')};
        }
        .ao-rating-header__button--edit {
          color: ${alpha(theme.module_txt_color || '000000', 60)};
        }
      `,
      );
    }
  }
}
