import { NgClass } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, Self, SimpleChanges } from '@angular/core';
import { BrandingColor } from '@ao/data-models';

@Component({
  selector: 'ao-info-bar',
  templateUrl: './info-bar.component.html',
  providers: [NgClass],
  styleUrls: ['./info-bar.component.scss'],
  standalone: false,
})
export class InfoBarComponent implements OnChanges {
  @HostBinding('class.ao-info-bar') className = true;

  @Input() iconName = 'info';
  @Input() color: BrandingColor = 'blue';

  @Output() dismiss = new EventEmitter<void>();
  get hasDismiss() {
    return this.dismiss.observed;
  }

  get classNames() {
    return {
      'ao-info-bar': true,
      [`ao-info-bar--${this.color}`]: this.color,
    };
  }

  constructor(@Self() private ngClass: NgClass) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.ngClass.ngClass = this.classNames;
    this.ngClass.ngDoCheck();
  }

  close() {
    this.dismiss.emit();
  }
}
