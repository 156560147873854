import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MultipartUploadService } from '@ao/common-ui';
import { Profile, SubordinatesPage } from '@ao/data-models';
import { environment } from '@ao/environments';
import { MediaItem } from '@ao/shared-data-models';
import * as loadImage from 'blueimp-load-image';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ProfileService {
  constructor(
    private http: HttpClient,
    private multipartUploadService: MultipartUploadService,
  ) {}

  getProfile(
    targetId: number,
    sourceId: number | null,
    sourceAuthCode: string,
    messageId: number,
  ): Observable<Profile> {
    const url = `${environment.apiBaseUrl}/api/v1/contacts/${targetId}/profile?sourceId=${sourceId}&sourceAuthCode=${sourceAuthCode}&messageId=${messageId}`;
    if (!sourceId) {
      return throwError(() => ({}));
    } else {
      return this.http.get<Profile>(url).pipe(
        map((res: any) => res.data[0]),
        catchError((error: any) => throwError(() => error)),
      );
    }
  }

  getSubordinates(
    targetId: number,
    sourceId: number | null,
    sourceAuthCode: string,
    messageId: number,
    searchQuery: string,
    pageSize: number,
    pageNumber: number,
  ): Observable<SubordinatesPage> {
    let urlParams = new HttpParams();
    urlParams = urlParams.appendAll({
      sourceId,
      sourceAuthCode,
      messageId,
      searchQuery,
      pageSize,
      pageNumber,
    });

    const url = `${environment.apiBaseUrl}/api/v1/contacts/${targetId}/subordinates?${urlParams.toString()}`;

    return this.http.get<{ data: SubordinatesPage[] }>(url).pipe(map((res) => res.data[0]));
  }

  getInsightByType(
    type: string,
    targetId: number,
    sourceId: number | null,
    sourceAuthCode: string,
    messageId: number,
    before: number,
  ): Observable<any> {
    const params: { [name: string]: any } = { sourceId, sourceAuthCode, messageId };
    if (before) {
      params.before = before;
    }
    const url = `${environment.apiBaseUrl}/api/v1/contacts/${targetId}/insights/${type}`;
    return this.http.get(url, { params }).pipe(
      // for indivdiual types we remove the wrapper array
      map((res: any) => res.data[0]),
      catchError((error: any) => throwError(() => error)),
    );
  }

  updateContactSettings(targetId: number, sourceId: number | null, sourceAuthCode: string, data: any): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/contacts/${targetId}/updateSettings?sourceId=${sourceId}&sourceAuthCode=${sourceAuthCode}`;
    return this.http.put(url, { ...data }).pipe(
      // for indivdiual types we remove the wrapper array
      map((res: any) => res),
      catchError((error: any) => throwError(() => error)),
    );
  }

  saveContactAvatar(contactId: number, sourceAuthCode: string, mediaId: number): Observable<any> {
    return this.http.put(
      `${environment.apiBaseUrl}/api/v1/contacts/${contactId}/avatar/${mediaId}/?authCode=${sourceAuthCode}`,
      { mediaFileId: mediaId },
    );
  }

  uploadContactAvatar(
    targetId: number,
    sourceAuthCode: string,
    file: File,
    keycode: string,
    isS3DirectUploadEnabled: boolean,
  ): Observable<MediaItem> {
    if (isS3DirectUploadEnabled) {
      return (
        file.type.startsWith('image')
          ? from(loadImage(file, {})).pipe(map((res) => ({ width: res.originalWidth, height: res.originalHeight })))
          : of({ width: null, height: null })
      ).pipe(
        switchMap(({ width, height }) => {
          const startUrl = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/media/upload/start`;
          const completeUrl = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/media/upload/complete`;

          return this.multipartUploadService.multipartUpload({
            file,
            width,
            height,
            startUrl,
            completeUrl,
            customFileName: 'avatar.png',
            dontSaveFolderItem: true,
          });
        }),
      );
    }

    const formData = new FormData();
    // avatar is exported from the canvas which has no filename, we still need to pass one since the backend uses the name+extension for file type validation
    formData.append('files', file, 'avatar.png');

    const url = `${environment.apiBaseUrl}/api/v1/contacts/${targetId}/avatar?authCode=${sourceAuthCode}`;
    return this.http.put(url, formData).pipe(
      // for indivdiual types we remove the wrapper array
      map((res: any) => res),
      catchError((error: any) => throwError(() => error)),
    );
  }

  getAvatarMedia(mediaId: number): Observable<{ data?: MediaItem }> {
    return this.http.get(`${environment.apiBaseUrl}/api/v1/viewer/media/${mediaId}`);
  }

  sendHomepage(keycode: string, targetId: number, sourceId: number, sourceAuthCode: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/${keycode}/sendHomepage/${targetId}?sourceId=${sourceId}&sourceAuthCode=${sourceAuthCode}`;
    return this.http.post(url, null).pipe(catchError((error: any) => throwError(() => error)));
  }

  createContact(keycode: string, creatorId: number, createContactAuthcode: string, formData: any): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/contacts/from-module`;
    return this.http
      .post(url, {
        ...formData.data,
        auth_code: createContactAuthcode,
        keycode,
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any) => throwError(() => error)),
      );
  }

  getAutoTranslationLanguages(keycode: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/v1/autoTranslations/${keycode}/supportedLanguages`;
    return this.http.get<any>(url).pipe(catchError((error: any) => throwError(() => error)));
  }
}
