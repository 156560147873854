import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'ao-flex-block',
  templateUrl: './flex-block.component.html',
  styleUrls: ['./flex-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FlexBlockComponent {
  @Input() disableFullWidth = false;
  @Input() alignItems: 'center' | 'end' | 'start' | undefined;
  @Input() justifyContent: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'stretch' | undefined;
  @Input() direction: 'row' | 'column' | undefined | 'column-reverse' | 'row-reverse' = 'row';
  @Input() wrap: 'nowrap' | 'wrap' | 'wrap-reverse' | undefined = undefined;
  @Input() gap?: `${string}px`;
  @Input() grow?: number;
  @Input() basis?: number;
  @Input() pad?: 'big' | 'bigger' | 'huge' | 'default';
  @Input() fullWidth? = false;
  @Input() fullHeight? = false;

  @HostBinding('class') get class() {
    const classNames = [this.direction, this.pad ? 'pad--' + this.pad : undefined].filter((val) => !!val);
    return classNames.join(' ');
  }

  @HostBinding('style') get myStyle(): SafeStyle {
    const fullWidth = this.fullWidth ? 'width:100%; ' : undefined;
    const fullHeight = this.fullHeight ? 'height:100%; ' : undefined;
    return this.sanitizer.bypassSecurityTrustStyle(
      `gap:${this.gap}; flex-wrap:${this.wrap}; flex-grow:${this.grow}; flex-basis:${this.basis}; ${
        this.justifyContent ? 'justify-content:' + this.justifyContent + ';' : ''
      } ${this.alignItems ? 'align-items:' + this.alignItems + ';' : ''}  
      ${fullWidth ? fullWidth : ''} 
      ${fullHeight ? fullHeight : ''} `,
    );
  }

  constructor(private sanitizer: DomSanitizer) {}
}
