import { getModuleRef } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { on } from '@ngrx/store';
import * as appActions from '../actions';
import { AppState, moduleAdapter } from './app-store.state';

export const moduleDataPickerOns = [
  on(appActions.DataPickerDataSelected, (state: AppState, { module, data }) => {
    // in case there several social feed modules
    return {
      ...state,
      modules: moduleAdapter.map(
        (m) => {
          if (m.type === 'contactinfo' && m.datapicker === module.id) {
            const errorMessage = i18n('Please select an option(#field#)');
            const errors =
              module.required && !data
                ? [...(m.errors || []), { error: errorMessage, params: { field: module.view_title || undefined } }]
                : m.errors
                  ? m.errors.filter(({ error }) => error !== errorMessage)
                  : [];
            return {
              ...m,
              errors,
            };
          }
          if (m.id === module.id) {
            return {
              ...m,
              _selected: data,
            };
          }
          return m;
        },
        { ...state.modules },
      ),
    };
  }),
  on(appActions.DataPickerMarkSubmitted, (state: AppState, { module }) => {
    return {
      ...state,
      modules: moduleAdapter.updateOne(
        {
          id: getModuleRef(module),
          changes: {
            _submitted: true,
          },
        },
        { ...state.modules },
      ),
    };
  }),
];
