import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AvatarSize } from '@ao/data-models';
import { ImageSource } from '@ao/shared-data-models';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

export type GenericPageHeaderTypes = 'error' | 'warning' | 'info' | 'avatar' | 'text' | 'icon';
@Component({
  selector: 'ao-generic-page-header',
  templateUrl: './generic-page-header.component.html',
  styleUrls: ['./generic-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GenericPageHeaderComponent {
  @HostBinding('class.ao-generic-page-header') className = true;
  @HostBinding('class.ao-generic-page-header--warning') get isWarning() {
    return this.type === 'warning';
  }
  @HostBinding('class.ao-generic-page-header--error') get isError() {
    return this.type === 'error';
  }
  @HostBinding('class.ao-generic-page-header--info') get isInfo() {
    return this.type === 'info';
  }

  @Input() type?: GenericPageHeaderTypes = 'info';
  @Input() iconName?: string;
  @Input() avatarSize?: AvatarSize = 'big';
  @Input() image?: string | SafeUrl;
  @Input() images?: ImageSource[];
  @Input() initials?: string;
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() text?: string;
  @Input() titleColor?: string;
  @Input() textColor?: string;
  @Input() subtitleColor?: string;

  @Output() imageClick = new EventEmitter<void>();

  _iconTypes = {
    info: {
      color: 'blue',
      icon: 'info',
    },
    warning: {
      color: 'yellow',
      icon: 'warning',
    },
    error: {
      color: 'red',
      icon: 'error',
    },
  };

  _uploadIconSize: any = {
    biggest: 42,
    big: 30,
    medium: 20,
    small: 15,
    tiny: 10,
    tiniest: 10,
    table: 10,
  };

  get _subTitle() {
    if (this.subTitle) {
      return this.subTitle;
    } else if (this.type === 'warning') {
      return i18n('Warning');
    } else if (this.type === 'error') {
      return i18n('Error');
    }
    return '';
  }
}
