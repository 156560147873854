import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, Optional, TrackByFunction } from '@angular/core';
import { MessageListMessageCategory } from '@ao/data-models';
import { alphaColor as alpha, color as c, invert as invertColor } from '@ao/utilities';
import { ThemeHandlerService } from '../../services/theme-handler/theme-handler.service';

@Component({
  selector: 'ao-category-tags',
  templateUrl: './category-tags.component.html',
  styleUrls: ['./category-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgClass],
  standalone: false,
})
export class CategoryTagsComponent {
  @HostBinding('class.ao-category-tags') className = true;
  @HostBinding('class.ao-category-tags--theme-style')
  @Input()
  useTheme?: boolean = false;

  @Input() categories: MessageListMessageCategory[] = [];
  @Input() limit?: number = 1;
  @Input() iconBackgroundColor?: string = '#000000';

  constructor(@Optional() themeHandler: ThemeHandlerService) {
    if (themeHandler) {
      // the ${theme.module_bg_color ? ... } validation is to revert the label color if module_bg_color is set to transparent
      themeHandler.registerStyle(
        'CategoryTags',
        (theme) => /* css*/ `

        .ao-category-tags--theme-style .ao-category-tags__category {
          background-color: ${c(theme.module_txt_color, '000000')};
          color: ${c(theme.module_bg_color, 'ffffff')};
        }
        ${
          theme.module_bg_color
            ? ''
            : `.ao-category-tags--theme-style .ao-category-tags__category-label {
          color: rgb(${invertColor(c(theme.module_txt_color, '000000'))});
        }`
        }
        .ao-category-tags--theme-style .ao-category-tags__more-categories {
          color: ${alpha(theme.module_txt_color, 30)};
        }
        .ao-category-tags--theme-style .ao-category-tags__more-categories:hover {
          color: ${alpha(theme.module_txt_color, 60)};
        }
      `,
      );
    }
  }

  trackByName: TrackByFunction<MessageListMessageCategory> = (index, item) => item.name;

  get remainingCategories() {
    return this.categories.length > this.limit
      ? this.categories
          .slice(this.limit)
          .map((cat) => cat.name.replace(/<[^>]*>/g, '')) // Remove HTML tags
          .join(', ')
      : '';
  }

  stopPropagation($event: Event) {
    $event.stopPropagation();
  }
}
