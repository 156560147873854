import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CustomIconName } from '@ao/data-models';

// eslint-disable-next-line no-useless-escape
const unitRegex = /[\D\%].*/;

@Component({
  selector: 'ao-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomIconComponent {
  @Input() icon: CustomIconName = '';
  @Input() size: string;
  @HostBinding('class.ao-custom-icon') className = true;
  @HostBinding('class.ao-custom-icon--active') @Input() active = true;
  @HostBinding('style.width') get iconSize(): string {
    const hasUnit = unitRegex.test(this.size);
    return hasUnit ? this.size : `${this.size}px`;
  }

  get _icon() {
    return this.icon && `assets/custom-icons/${this.icon}.svg`;
  }
}
