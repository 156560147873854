<ng-container *ngIf="isNativeApp; else downloadLink">
  <ao-uploaded-file
    [name]="file.name"
    [size]="file.size"
    [type]="file.mimeType"
    [inChat]="true"
    (click)="nativeAppDownloadFile(file.url)"
  ></ao-uploaded-file>
</ng-container>
<ng-template #downloadLink>
  <a [href]="file.url" target="_blank">
    <ao-uploaded-file [name]="file.name" [size]="file.size" [type]="file.mimeType" [inChat]="true"></ao-uploaded-file>
  </a>
</ng-template>
