import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ao-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BadgeComponent {
  @HostBinding('class.ao-badge') className = true;
  @HostBinding('class.ao-badge--large') get largeClassName() {
    return this.size === 'large';
  }
  @Input() count: number;
  @Input() size: string;
}
