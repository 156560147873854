import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ClientListItem } from '@ao/data-models';

@Component({
  selector: 'ao-homepagelist-item',
  templateUrl: './homepagelist-item.component.html',
  styleUrls: ['./homepagelist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HomepagelistItemComponent {
  @HostBinding('class.ao-homepagelist-item') className = true;

  @Input() client: ClientListItem;

  @Output() pickClient = new EventEmitter();
}
