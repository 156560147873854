import { ConnectedPosition } from '@angular/cdk/overlay';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BrowserService } from '@ao/utilities';
import * as loadImage from 'blueimp-load-image';
import { BehaviorSubject, debounceTime, filter, map, Subject, takeUntil } from 'rxjs';

interface CropperState {
  loaded: boolean;
  width: number;
  height: number;
  x: number;
  y: number;
  mouse: {
    x: number;
    y: number;
  };
  dragging: boolean;
  zoom: number;
  touched: boolean;
}

@Component({
  selector: 'ao-app-media-upload',
  templateUrl: './app-media-upload.component.html',
  styleUrls: ['./app-media-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppMediaUploadComponent implements AfterViewInit {
  private zone = inject(NgZone);
  private browserService = inject(BrowserService);
  private cdr = inject(ChangeDetectorRef);

  @HostBinding('class.ao-app-media-upload') className = true;

  @Input({ required: false }) height = 200;
  @Input({ required: false }) width = 200;
  @Input({ required: false }) imageUrl: string | SafeUrl;
  @Input({ required: false }) hasRemoveImage = true;

  @Output() cropped = new EventEmitter<Blob | null>();
  @Output() imageUrlChange = new EventEmitter<string>();

  @ViewChild('filePicker') filePicker!: ElementRef<HTMLInputElement>;
  @ViewChild('file', { static: true }) fileRef: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  hasImage = false;
  hasUploadedImage = false;
  showMediaOverlay = false;

  isIOS = this.browserService.isIOS;

  private context: CanvasRenderingContext2D;
  private zoomBeforePinch: number = null;
  private resource: HTMLImageElement;
  private destroy$ = new Subject<void>();
  state$ = new BehaviorSubject<CropperState>({
    loaded: false,
    width: null,
    height: null,
    x: 0,
    y: 0,
    dragging: false,
    zoom: 1,
    mouse: {
      x: null,
      y: null,
    },
    touched: false,
  });

  readonly mobileOverlayPosition: ConnectedPosition[] = [
    { originY: 'bottom', originX: 'start', overlayX: 'start', overlayY: 'bottom' },
  ];
  readonly fullOverlayPosition: ConnectedPosition[] = [
    { originY: 'top', originX: 'center', overlayX: 'center', overlayY: 'bottom' },
  ];

  touched$ = this.state$.pipe(map((s) => s.touched));
  loading$ = this.state$.pipe(map((state) => state.loaded));

  ngAfterViewInit() {
    this.context = (<HTMLCanvasElement>this.canvas.nativeElement).getContext('2d');

    this.state$
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((state) => {
        if (state.loaded) {
          this.getResizedCanvas().toBlob((blob) => {
            this.zone.run(() => {
              this.cropped.emit(blob);
            });
          });
        }
      });
  }

  handleImageError() {
    this.imageUrl = false;
  }

  onFile(event: Event) {
    const input = <HTMLInputElement>event.target;
    if (input.files.length) {
      const file: File = input.files[0];
      this.readFile(file, false);

      input.value = '';
    }
  }

  onInitialsClick() {
    (<HTMLInputElement>this.fileRef.nativeElement).click();
  }

  onMouseWheelEvent(event: WheelEvent) {
    if (event.deltaY < 0) {
      this.zoom(1.1, { x: event.offsetX, y: event.offsetY });
    } else {
      this.zoom(0.9, { x: event.offsetX, y: event.offsetY });
    }

    event.preventDefault();
  }

  @HostListener('window:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.resource) {
      return;
    }

    const current = this.state$.getValue();
    this.state$.next({
      ...current,
      mouse: {
        x: event.clientX,
        y: event.clientY,
      },
      dragging: true,
    });
  }

  @HostListener('panstart', ['$event'])
  panStartListener(event) {
    if (!this.resource) {
      return;
    }
    const current = this.state$.getValue();
    this.state$.next({
      ...current,
      mouse: {
        x: event.center.x,
        y: event.center.y,
      },
    });
  }

  @HostListener('panend', ['$event'])
  @HostListener('window:mouseup', ['$event'])
  mouseUpListener() {
    if (!this.resource) {
      return;
    }
    const current = this.state$.getValue();
    this.state$.next({
      ...current,
      mouse: null,
      dragging: false,
    });
  }

  @HostListener('window:mousemove', ['$event'])
  mouseMoveListener(event: MouseEvent) {
    if (!this.resource) {
      return;
    }
    const current = this.state$.getValue();
    if (!current.dragging) {
      return;
    }
    this.pan(event.clientX, event.clientY);
  }

  @HostListener('panmove', ['$event'])
  panMoveListener(event) {
    if (!this.resource) {
      return;
    }
    this.pan(event.center.x, event.center.y);
  }

  private pan(newX: number, newY: number) {
    const current = this.state$.getValue();

    const imageX = current.x;
    const imageY = current.y;

    const { x, y } = current.mouse
      ? this.boundedCoords(imageX, imageY, current.mouse.x - newX, current.mouse.y - newY, current.zoom)
      : current;

    this.state$.next({
      ...current,
      x,
      y,
      mouse: {
        x: newX,
        y: newY,
      },
      touched: true,
    });
  }

  @HostListener('pinchstart', ['$event'])
  pinchStartListener() {
    this.zoomBeforePinch = this.state$.getValue().zoom;
  }

  @HostListener('pinch', ['$event'])
  pinchListener(event) {
    const current = this.state$.getValue().zoom;
    const desired = this.zoomBeforePinch * event.scale;
    this.zoom(desired / current, { x: this.width / 2, y: this.height / 2 });
  }

  @HostListener('pinchend', ['$event'])
  pinchEndListener() {
    this.zoomBeforePinch = null;
  }

  @HostListener('document:selectstart', ['$event'])
  preventSelection(event: Event) {
    if (this.state$.getValue().dragging) {
      event.preventDefault();
    }
  }

  removeImage() {
    this.filePicker.nativeElement.value = '';
    this.hasUploadedImage = false;
    this.showMediaOverlay = false;
    this.imageUrl = null;

    this.imageUrlChange.emit('');
  }

  private getResizedCanvas(): HTMLCanvasElement {
    const current = this.state$.getValue();
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = this.width;
    canvas.height = this.height;

    context.drawImage(
      this.resource,
      current.x,
      current.y,
      this.width / current.zoom,
      this.height / current.zoom,
      0,
      0,
      this.width,
      this.height,
    );

    return canvas;
  }

  private zoom(scale, m: { x: number; y: number }) {
    const current = this.state$.getValue();
    // BUGS-765: Safari can't handle a zoom less than 0.16
    const minZoom = Math.max(this.height / current.height, this.width / current.width, 0.16);
    const nextZoom = Math.max(current.zoom * scale, minZoom);

    const nextX = current.x + m.x / current.zoom - m.x / nextZoom;
    const nextY = current.y + m.y / current.zoom - m.y / nextZoom;

    this.state$.next({
      ...current,
      ...this.boundedCoords(nextX, nextY, 0, 0, nextZoom),
      touched: true,
    });
  }

  private boundedCoords(x: number, y: number, dx: number, dy: number, zoom: number) {
    const current = this.state$.getValue();
    x = Math.min(Math.max(x + dx / zoom, 0), current.width - this.width / current.zoom);
    y = Math.min(Math.max(y + dy / zoom, 0), current.height - this.height / current.zoom);

    return { x, y, zoom };
  }

  private fitImageToCanvas(width, height) {
    // BUGS-765: Safari can't handle a zoom less than 0.16
    const minZoom = Math.max(this.height / height, this.width / width, 0.16);

    return {
      width,
      height,
      zoom: minZoom,
      x: (width - this.width / minZoom) / 2,
      y: (height - this.height / minZoom) / 2,
    };
  }

  private readFile(value: Blob, initial) {
    loadImage(
      value,
      (img: HTMLImageElement) => {
        this.prepareImage(img, initial);
      },
      { orientation: true },
    );
  }

  private prepareImage(image: string | HTMLImageElement, initial) {
    this.state$
      .pipe(
        filter((state) => state.loaded),
        takeUntil(this.destroy$),
      )
      .subscribe((state) => {
        this.context.clearRect(0, 0, this.width, this.height);
        this.addImageToCanvas(this.context, state);
        this.drawMask(this.context);
      });

    if (typeof image === 'string') {
      this.resource = new Image();
      const xhr = new XMLHttpRequest();

      const reader = new FileReader();
      xhr.onload = function () {
        reader.readAsDataURL(this.response);
      };
      // this step is necessary for it to work on safari
      reader.onload = (e) =>
        this.zone.run(() => {
          this.resource.src = <string>e.target['result'];
        });
      this.resource.onload = () =>
        this.zone.run(() => {
          this.imageReady(initial);
        });

      xhr.open('GET', image, true);
      xhr.open('GET', image.replace(/^http:/, 'https:'), true);
      xhr.responseType = 'blob';
      xhr.send();
    } else {
      this.resource = image;
      this.imageReady(initial);
    }
  }

  private imageReady(initial) {
    this.state$.next({
      loaded: true,
      ...this.fitImageToCanvas(this.resource.width, this.resource.height),
      dragging: false,
      mouse: {
        x: null,
        y: null,
      },
      touched: !initial,
    });
    this.hasImage = true;
    this.hasUploadedImage = true;

    this.cdr.markForCheck();
  }

  private addImageToCanvas(context: CanvasRenderingContext2D, state: CropperState) {
    if (!this.resource) {
      return;
    }
    context.drawImage(
      this.resource,
      state.x,
      state.y,
      this.width / state.zoom,
      this.height / state.zoom,
      0,
      0,
      this.width,
      this.height,
    );
  }

  private drawMask(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo(0, this.height);
    context.lineTo(this.width, this.height);
    context.lineTo(this.width, 0);
    context.closePath();
    context.moveTo(this.width, 0);
    context.arc(this.width / 2, this.height / 2, this.width / 2 - 1, 0, 2 * Math.PI);
    context.closePath();
    context.fillStyle = 'rgba(255, 255, 255, 1)';
    context.fill('evenodd');
  }
}
