import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Directive({
  selector: '[aoUploadFileDropAreaBottomLayerTemplate]',
  standalone: false,
})
export class UploadFileDropAreaBottomLayerTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

// provides a standard dotted line drop area and styles + outputs upon file drag
@Component({
  selector: 'ao-upload-file-drop-area',
  templateUrl: './upload-file-drop-area.component.html',
  styleUrls: ['./upload-file-drop-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UploadFileDropAreaComponent {
  @HostBinding('class.ao-upload-file-drop-area') className = true;
  @HostBinding('class.ao-upload-file-drop-area--file-over') get colorYellow() {
    return !!this.fileOver;
  }

  @HostBinding('class.ao-upload-file-drop-area--error')
  @Input()
  error = false;

  fileOver = false;

  @Output() fileDragged = new EventEmitter<any>();
  @Output() fileDragCancel = new EventEmitter<any>();

  @ContentChild(UploadFileDropAreaBottomLayerTemplateDirective, { read: TemplateRef })
  bottomLayerTemplate: TemplateRef<any>;
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.fileOver) {
      this.fileOver = true;
      this.fileDragged.emit(true);
    }
  }

  @HostListener('drop', ['$event']) public onDragDrop() {
    this.fileOver = false;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragCancel();
  }

  dragCancel() {
    this.fileOver = false;
    this.fileDragCancel.emit(true);
  }
}
