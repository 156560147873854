import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaUpload } from '@ao/data-models';
import { MediaItem } from '@ao/shared-data-models';
import { TranslateModule } from '@ngx-translate/core';
import { CommonUiModule } from '../../common-ui.module';

@Component({
  selector: 'ao-uploaded-attachment',
  templateUrl: './uploaded-attachment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, CommonUiModule],
})
export class UploadedAttachmentComponent {
  _media: MediaUpload | MediaItem;

  @Input() uploaded: boolean;
  @Input() processed: boolean;
  @Input() progress?: number;

  @Input() set mediaItem(item: MediaItem | MediaUpload) {
    this._media = item;
  }

  get isVideo() {
    return this._media?.type?.includes('video');
  }

  @Output() remove = new EventEmitter<void>();

  onAttachmentRemove() {
    this.remove.emit();
  }
}
