import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AppButtonColor } from '@ao/data-models';

@Component({
  selector: 'ao-popover-item',
  templateUrl: './popover-item.component.html',
  styleUrls: ['./popover-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PopoverItemComponent {
  @Input({ required: false }) label?: string;
  @Input({ required: false }) icon?: string;
  @Input({ required: false }) color?: AppButtonColor = 'ink';

  @HostBinding('class.ao-popover-item') className = true;

  @HostBinding('class.ao-popover-item--red') get colorRed() {
    return this.color === 'red';
  }

  @HostBinding('class.ao-popover-item--green') get colorGreen() {
    return this.color === 'green';
  }

  @HostBinding('class.ao-popover-item--yellow') get colorYellow() {
    return this.color === 'yellow';
  }

  @HostBinding('class.ao-popover-item--blue') get colorBlue() {
    return this.color === 'blue';
  }

  @HostBinding('class.ao-popover-item--ink') get colorInk() {
    return this.color === 'ink';
  }

  @HostBinding('class.ao-popover-item--snow') get colorSnow() {
    return this.color === 'snow';
  }

  @HostBinding('class.ao-popover-item--transparent') get colorTransparent() {
    return this.color === 'transparent';
  }
}
